<template>
    <div>
        <!-- Text Input -->
        <input v-if="item.type === 'Text'" type="text" class="form-control" :value="item.newValue"
            @input="$emit('input', $event.target.value)" :disabled="isReadOnly" maxlength="255" />

        <!-- Textarea -->
        <textarea v-else-if="item.type === 'MultiLine-Text'" class="form-control min-rows" :value="item.newValue"
            @input="$emit('input', $event.target.value)" :disabled="isReadOnly" maxlength="2000" />

        <!-- Number Input -->
        <input v-else-if="['Integer', 'Decimal', 'Percent'].includes(item.type)" type="number" class="form-control"
            :value="item.newValue" @input="$emit('input', $event.target.value)" :disabled="isReadOnly" />

        <!-- Currency Input -->
        <currency-input v-else-if="item.type === 'Currency'" class="form-control" :value="item.newValue"
            @input="$emit('input', $event)" :disabled="isReadOnly" :precision="0" />

        <!-- Date Input -->
        <b-form-datepicker v-else-if="item.type === 'Date'" :value="item.newValue" @input="$emit('input', $event)"
            :disabled="isReadOnly" :locale="'en-AU'" class="form-control" />

        <!-- Select/Picklist -->
        <b-form-select v-else-if="item.type === 'Picklist'" :value="item.newValue" @input="$emit('input', $event)"
            :disabled="isReadOnly" class="form-control">
            <option value=""></option>
            <option v-for="option in item.options" :key="option" :value="option">
                {{ option }}
            </option>
        </b-form-select>
    </div>
</template>

<script>
export default {
    name: 'ComponentByType',
    props: {
        item: {
            type: Object,
            required: true
        },
        isReadOnly: {
            type: Boolean,
            default: false
        }
    }
};
</script>
