import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "./htmlclass.module";
import config from "./config.module";

import policyAlert from './policyalert.module';

import newBusiness from './modules/newBusiness';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    htmlClass,
    config,
    policyAlert,
    newBusiness: {
      namespaced: true,
      ...newBusiness
    }
  },
  state: {
    wizardTitle: null,
    wizardSubtitle: null,
    transactionStatus: null,
    transactionSubStatus: null,
    transactionBoxFolderLink: null,
    crmRecordLink: null,
    nextBatchTransaction: null,
    claimLodged: false,
    claimFormViewLink: null,
    claimFormInternalLink: null,
    insurerNotified: false,
    quoteBeingUpdated: null,
    appData: null,
    K_BrokerCompany: null,
    brokerLogos: {
      'Collective Insurance Brokers': '/media/logos/CIB_Logo.jpeg',
      'Body Corporate Brokers': '/media/logos/BCB_Logo.jpeg'
    },
  },
  mutations: {
    setBrokerCompany(state, company) {
      state.K_BrokerCompany = company;
    },
    setWizardTitle(state, title) {
      state.wizardTitle = title;
    },
    setWizardSubtitle(state, subtitle) {
      state.wizardSubtitle = subtitle;
    },
    setTransactionStatus(state, transactionStatus) {
      state.transactionStatus = transactionStatus;
    },
    setTransactionSubStatus(state, transactionSubStatus) {
      state.transactionSubStatus = transactionSubStatus;
    },
    setTransactionBoxFolderLink(state, txnBoxFolderLink) {
      state.transactionBoxFolderLink = txnBoxFolderLink;
    },
    setCrmRecordLink(state, crmRecordLink) {
      state.crmRecordLink = crmRecordLink;
    },
    setNextBatchTransaction(state, nextBatchTransaction) {
      state.nextBatchTransaction = nextBatchTransaction;
    },
    setClaimLodged(state, status) {
      state.claimLodged = status;
    },
    setClaimFormInternalLink(state, claimFormInternalLink) {
      state.claimFormInternalLink = claimFormInternalLink;
    },
    setClaimFormViewLink(state, link) {
      state.claimFormViewLink = link;
    },
    setInsurerNotified(state, value) {
      state.insurerNotified = value;
    },
    SET_QUOTE_BEING_UPDATED(state, quote) {
      state.quoteBeingUpdated = quote
    },
    UPDATE_QUOTE(state, updatedQuote) {
      state.quoteBeingUpdated = { ...state.quoteBeingUpdated, ...updatedQuote }
    },
    SET_APP_DATA(state, appData) {
      state.appData = appData
    },
    updateDeclarationReview(state, reviewed) {
      if (state.appData?.transaction) {
        state.appData.transaction.K_DeclarationReviewed = reviewed;
      }
    },
  },

  actions: {
    async validateForm({ commit, state }) {
      commit('CLEAR_VALIDATION_STATE');
      // Add validation logic
      return Object.keys(state.newBusiness.validationState).length === 0;
    },

    async handlePolicyChange({ dispatch }) {
      await dispatch('saveNewBusinessData');
    },

    async handleAssetChange({ dispatch }) {
      await dispatch('saveNewBusinessData');
    },

    updateWizardTitle({ commit }, { type, policyName }) {
      const title = `${type} - ${policyName}`;
      commit('setWizardTitle', title);
    },

    async saveNewBusinessData({ commit, state }, payload) {
      commit('SET_SAVING', true);
      try {
        // API call to save data
        const dataToSave = {
          asset: state.newBusiness.asset,
          policy: state.newBusiness.policy,
          transaction: state.newBusiness.transaction,
          statusFlags: state.newBusiness.statusFlags  // Make sure this is included
        };

        const response = await this._vm.$newBusinessDataService.saveWizardAppData(
          dataToSave
        );

        if (response?.statusFlags) {
          commit('SET_STATUS_FLAGS', response.statusFlags);
        }

        // Update store with response
        commit('SET_ASSET', response.asset);
        commit('SET_POLICY', response.policy);
        commit('SET_TRANSACTION', response.transaction);

        return response;
      } catch (error) {
        console.error('Error saving new business data:', error);
        throw error;
      } finally {
        commit('SET_SAVING', false);
      }
    },

    async updateBrokerCompany({ commit, dispatch }, company) {
      commit('UPDATE_BROKER_COMPANY', company);
      await dispatch('saveNewBusinessData');
    },


    setQuoteBeingUpdated({ commit }, quote) {
      commit('SET_QUOTE_BEING_UPDATED', _.cloneDeep(quote))
    },
    updateQuote({ commit }, updatedQuote) {
      commit('UPDATE_QUOTE', updatedQuote)
    },
    setAppData({ commit }, appData) {
      commit('SET_APP_DATA', appData)
    },

    async updateDeclarationReviewStatus({ commit, dispatch }, reviewed) {
      try {
        // Call API to update status
        await this._vm.$commonDataService.updateDeclarationReviewStatus(reviewed);

        // Update store
        commit('updateDeclarationReview', reviewed);

        // Refresh entire app data to ensure consistency
        await dispatch('refreshAppData');
      } catch (error) {
        console.error('Failed to update declaration review status:', error);
        throw error;
      }
    },

    async refreshAppData({ commit }) {
      try {
        const { data } = await this._vm.$renewalDataService.getWizardAppData();
        commit('setAppData', data.result);
      } catch (error) {
        console.error('Failed to refresh app data:', error);
        throw error;
      }
    }
  },

  getters: {
    isClaimLodged: state => state.claimLodged,
    isInsurerNotified: state => state.insurerNotified,
    claimFormLink: (state) => {
      return state.insurerNotified || !state.claimFormInternalLink
        ? state.claimFormViewLink
        : state.claimFormInternalLink;
    },
    quoteBeingUpdated: state => state.quoteBeingUpdated,
    appData: state => state.appData,
    getBrandLogo: state => {
      return state.brokerLogos[state.K_BrokerCompany] || state.brokerLogos['Collective Insurance Brokers']; // default to CIB
    }
  }
});
