<template>
    <b-card body-class="px-2 py-2" class="mt-5 mb-5">
        <!-- Header -->
        <h4 class="fs-2x fw-bolder mb-0 text-center py-4" v-b-toggle="id">
            {{ configuration.title }}
            <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md ml-2" title="Click to Expand"></i>
            <i v-if="isExpanded" class="fas fa-angle-double-up fa-md ml-2" title="Click to Collapse"></i>
        </h4>

        <b-collapse :id="id" v-model="isExpanded">
            <!-- Non-disclosure properties table -->
            <div class="row" v-if="nonDisclosureProperties.length">
                <div v-for="(chunk, index) in dataChunks" :key="index" class="col-sm-6 table-responsive">
                    <table class="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th width="45%">Name</th>
                                <th width="30%" v-if="showNewValue">{{ configuration.newValueTitle }}</th>
                                <th v-if="configuration.allowDeletion"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, itemIndex) in chunk" :key="itemIndex">
                                <td>
                                    <label class="col-form-label ml-2">{{ item.displayName }}</label>
                                </td>
                                <td v-if="showNewValue">
                                    <component-by-type :item="item" :is-read-only="isReadOnly"
                                        @input="handlePropertyInput(item, $event)" />
                                </td>
                                <td v-if="configuration.allowDeletion">
                                    <button class="btn p-0" @click.prevent="deleteProperty(item)" :disabled="isReadOnly"
                                        v-if="item.isCustomItem">
                                        <i class="fa fa-minus-circle fa-1.5x text-danger" style="opacity: 0.75;"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Disclosures Section -->
            <div class="mt-7">
                <h4 class="text-center mb-5">Disclosures</h4>
                <div v-for="(question, index) in disclosureQuestions" :key="index">
                    <b-card body-class="px-2 py-2" class="mb-5" border-variant="secondary">
                        <!-- Main Question -->
                        <div class="form-group row">
                            <div class="col-12 font-weight-bold font-size-h5">
                                <div class="row pl-3">
                                    <div class="col-1 text-success">{{ index + 1 }}.</div>
                                    <div class="col-11 mb-4" style="margin-left: -60px;">
                                        {{ question.displayName }}
                                    </div>
                                </div>
                            </div>
                            <!-- Yes/No Toggle -->
                            <div class="col-12 mb-2 ml-10">
                                <b-input-group :disabled="isReadOnly" size="lg" class="ml-4">
                                    <b-form-checkbox switch v-model="question.newValue" :value="'Yes'"
                                        :unchecked-value="'No'" class="mr-n2" :disabled="isReadOnly"
                                        @input="handleDisclosureToggle(question, $event)" />
                                    <span class="pl-3 pt-1 font-weight-bold">
                                        {{ question.newValue === 'Yes' ? 'Yes' : 'No' }}
                                    </span>
                                </b-input-group>
                            </div>

                            <!-- Child Questions -->
                            <div v-if="question.newValue === 'Yes' && question.children?.length" class="col-12 ml-10">
                                <div v-for="(child, childIndex) in question.children" :key="childIndex">
                                    <template v-if="shouldShowChildItem(question, child)">
                                        <label class="col-12 col-form-label font-weight-bold mt-3">
                                            {{ index + 1 }}.{{ childIndex + 1 }} - {{ child.displayName }}
                                        </label>
                                        <div class="col-6">
                                            <component-by-type :item="child" :is-read-only="isReadOnly"
                                                @input="handleChildPropertyInput(question, child, $event)"
                                                @change="handlePropertyInput(item, $event)" />
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>
        </b-collapse>
    </b-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import _ from 'lodash';
import ComponentByType from "./ComponentByType.vue";

export default {
    name: 'NewBusinessBuildingProperties',

    props: {
        configuration: {
            type: Object,
            required: true
        },
        isReadOnly: {
            type: Boolean,
            default: false
        }
    },

    components: {
        ComponentByType
    },

    data() {
        return {
            isExpanded: true
        };
    },

    computed: {
        ...mapState('newBusiness', ['declarationData']),

        id() {
            return this.configuration?.title?.split(" ").join("_") || "";
        },

        showNewValue() {
            return this.configuration?.showNewValue !== false;
        },

        nonDisclosureProperties() {
            return this.declarationData.assetPropertiesData?.filter(prop => !prop.isDisclosure) || [];
        },

        disclosureQuestions() {
            return this.declarationData.assetPropertiesData?.filter(prop => prop.isDisclosure) || [];
        },

        dataChunks() {
            return _.chunk(this.nonDisclosureProperties, Math.ceil(this.nonDisclosureProperties.length / 2));
        }
    },

    methods: {
        ...mapMutations('newBusiness', ['UPDATE_DECLARATION_DATA']),
        ...mapActions('newBusiness', ['saveNewBusinessData']),

        handlePropertyInput(item, value) {
                // Just update local state and store
                item.newValue = value;

                // Update store
                this.UPDATE_DECLARATION_DATA({
                    ...this.declarationData,
                    assetPropertiesData: [...this.declarationData.assetPropertiesData]
                });
            },

        async handleDisclosureToggle(question, value) {
            question.newValue = value;
            // Reset child values when toggling to No
            if (value === 'No' && question.children) {
                question.children.forEach(child => {
                    child.newValue = null;
                });
            }

            // Update store
                    this.UPDATE_DECLARATION_DATA({
                        ...this.declarationData,
                        assetPropertiesData: [...this.declarationData.assetPropertiesData]
                    });
        },

        async handleChildPropertyInput(question, child, value) {
            child.newValue = value;

            // Update store
                    this.UPDATE_DECLARATION_DATA({
                        ...this.declarationData,
                        assetPropertiesData: [...this.declarationData.assetPropertiesData]
                    });
        },

        async saveChanges() {
            this.UPDATE_DECLARATION_DATA({
                ...this.declarationData,
                assetPropertiesData: [...this.declarationData.assetPropertiesData]
            });
        },

        deleteProperty(item) {
            const index = this.declarationData.assetPropertiesData.indexOf(item);
            if (index !== -1) {
                const updatedData = [...this.declarationData.assetPropertiesData];
                updatedData.splice(index, 1);
                this.UPDATE_DECLARATION_DATA({
                    ...this.declarationData,
                    assetPropertiesData: updatedData
                });
            }
        },

        shouldShowChildItem(question, child) {
            // Add your conditional logic here for showing/hiding child items
            // Example:
            const commercialPurposes = this.disclosureQuestions.find(
                q => q.apiKey === 'K_BuildingUsedForCommercialPurposes'
            );

            if (commercialPurposes?.newValue !== 'Yes') {
                const kitchenRelatedFields = [
                    'K_CapacityOfDeepFryersLitres',
                    'K_FiltersFlueDuctsCleanedProfessionally',
                    'K_HowOftenAreFiltersFlueAndDuctsCleaned'
                ];
                if (kitchenRelatedFields.includes(child.apiKey)) {
                    return false;
                }
            }

            return true;
        },

        emitUpdate() {
            this.UPDATE_DECLARATION_DATA({
                ...this.declarationData,
                assetPropertiesData: [...this.declarationData.assetPropertiesData]
            });
        },

        updateStoreData() {
            // Update store state
            this.UPDATE_DECLARATION_DATA({
                ...this.declarationData,
                assetPropertiesData: [...this.declarationData.assetPropertiesData]
            });
        },
    }

};
</script>

<style scoped>
.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}

textarea {
    overflow: hidden;
}

.display-name {
    display: inline-block;
    text-indent: 30px;
}
</style>
