<template>
    <div>
        <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
            <h4 class="font-weight-bold text-dark" v-b-toggle="'claims-history-panel'">
                <i class="fas fa-house-damage fa-md text-primary"></i>
                <span class="ml-2" style="vertical-align: center">Claims History</span>

                <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
                <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
            </h4>

            <b-collapse id="claims-history-panel" v-model="isExpanded">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th width="2%"></th>
                                <th width="20%">Insurer</th>
                                <th width="10%">Reference</th>
                                <th width="14%">Date of Loss</th>
                                <th width="10%">Status</th>
                                <th width="10%">Incurred Amount</th>
                                <th width="30%">Description</th>
                                <th width="4%" v-if="!isReadOnly"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(claim, index) in claims" v-bind:key="index">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    <input v-if="!isReadOnly" type="text" class="form-control" v-model="claim.K_Insurer" />
                                    <span v-else>{{ claim.K_Insurer }}</span>
                                </td>
                                <td>
                                    <input v-if="!isReadOnly" type="text" class="form-control"
                                        v-model="claim.K_InsurerClaimNumber" />
                                    <span v-else>{{ claim.K_InsurerClaimNumber }}</span>
                                </td>
                                <td>
                                    <b-form-datepicker v-if="!isReadOnly" v-model="claim.K_DateOfLoss" :locale="'en-AU'"
                                        menu-class="calendar-dropdown" boundary="window" :drop-direction="'down'" no-flip
                                        :date-format-options="{
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        }" />
                                    <span v-else>
                                        {{ claim.K_DateOfLoss | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
                                    </span>
                                </td>
                                <td>
                                    <b-form-select v-if="!isReadOnly" v-model="claim.K_Status" :options="statusOptions" />
                                    <span v-else>{{ claim.K_Status }}</span>
                                </td>
                                <td>
                                    <currency-input v-if="!isReadOnly" class="form-control" v-model="claim.K_TotalClaimCost"
                                        :allow-negative="false" :distraction-free="false" :precision="0" />
                                    <span v-else>{{ claim.K_TotalClaimCost | currency }}</span>
                                </td>
                                <td>
                                    <textarea v-if="!isReadOnly" class="form-control"
                                        v-model="claim.K_DescriptionOfLossDamage" rows="1" />
                                    <span v-else>{{ claim.K_DescriptionOfLossDamage }}</span>
                                </td>
                                <td v-if="!isReadOnly">
                                    <button class="btn btn-link text-danger p-0" @click="removeClaim(index)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="!isReadOnly" class="d-flex justify-content-end mt-4">
                    <b-button variant="success" @click="addClaim">
                        <b-spinner small v-if="isSaving" class="mr-1"></b-spinner>
                        <i class="fas fa-plus"></i> Add Claim
                    </b-button>
                </div>

                <div v-if="localClaims.length === 0" class="text-center mt-4">
                    No claims history available
                </div>

                <div class="mt-4">
                    <b-button class="btn btn-primary" @click="saveClaimsChanges" :disabled="!hasChanges">
                        <i class="fas fa-save"></i> Save Changes
                    </b-button>
                </div>

            </b-collapse>
        </b-card>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
    name: "ClaimsHistory",

    props: {
        isReadOnly: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isExpanded: true,
            localClaims: [],
            originalClaims: [],
            hasUnsavedChanges: false,
            isSaving: false,
            statusOptions: [
                { value: null, text: 'Select Status' },
                { value: 'Open', text: 'Open' },
                { value: 'Closed', text: 'Closed' },
                { value: 'Pending', text: 'Pending' },
                { value: 'Withdrawn', text: 'Withdrawn' }
            ]
        };
    },

    watch: {
        'claims': {
            deep: true,
            handler() {
                this.handleChange();
            }
        }
    },

    methods: {
        ...mapMutations('newBusiness', [
            'UPDATE_CLAIMS',
            'ADD_CLAIM',
            'REMOVE_CLAIM'
        ]),
        ...mapActions('newBusiness', ['saveNewBusinessData']),

        addClaim() {
            this.ADD_CLAIM();
            this.hasUnsavedChanges = true;
        },

        removeClaim(index) {
            this.REMOVE_CLAIM(index);
            this.hasUnsavedChanges = true;
        },

        handleChange() {
            this.hasUnsavedChanges = true;
        },

        async saveClaimsChanges() {
            try {
                this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                this.isSaving = true;

                await this.saveNewBusinessData();
                this.hasUnsavedChanges = false;
            } catch (error) {
                console.error('Error saving claims:', error);
                this.$emit('handleError', error);
            } finally {
                this.isSaving = false;
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }
        },
    },

    computed: {
        ...mapState('newBusiness', ['claims']),

        hasChanges() {
            return this.hasUnsavedChanges;
        }
    }
};
</script>

<style scoped>
.form-control {
    min-width: 100px;
}

textarea.form-control {
    min-height: 38px;
    resize: vertical;
}

/* Add these new styles for calendar */
.b-form-datepicker {
    position: relative;
}

/* Override Bootstrap styles for the calendar dropdown */
.b-calendar {
    position: absolute !important;
    z-index: 1050 !important;
    /* Higher z-index to ensure it appears above other elements */
    background: white;
    border: 1px solid #dee2e6;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    margin-top: 2px;
}

/* Ensure dropdown has enough width */
.b-calendar .b-calendar-inner {
    min-width: 250px;
}

/* Remove scrollbar from calendar grid */
.b-calendar .b-calendar-grid {
    overflow: visible;
}

/* Ensure the calendar popup renders above the table */
td .b-form-datepicker {
    position: relative;
}

td .b-calendar {
    position: absolute;
    left: 0;
    max-height: none !important;
    overflow: visible !important;
}
</style>
