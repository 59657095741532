<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    name: "BuildingDetails",

    props: {
        refData: Object,
        isReadOnly: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapState('newBusiness', {
            transaction: state => state.transaction,
            policy: state => state.policy,
            asset: state => state.asset,
            storeIsReadOnly: state => state.isReadOnly,
            isExpanded: state => state.isExpanded.buildingDetails
        }),

        effectiveReadOnly() {
            return this.storeIsReadOnly || this.isReadOnly;
        },

        isExpanded: {
            get() {
                return this.$store.state.newBusiness.isExpanded.buildingDetails;
            },
            set(value) {
                this.SET_EXPANDED({
                    section: 'buildingDetails',
                    isExpanded: value
                });
            }
        },

        hasAsset() {
            return !!this.asset?.id;
        }
    },

    methods: {
        ...mapActions('newBusiness', [
            'saveNewBusinessData'
        ]),
        ...mapMutations('newBusiness', [
            'SET_ASSET',
            'SET_EXPANDED'
        ]),

        async handleAssetChange() {
            await this.saveNewBusinessData();
        }
    },

    watch: {
        'asset.K_PlanNumber': {
            handler(newVal) {
                this.SET_ASSET({
                    ...this.asset,
                    Name: newVal || 'Draft Building'
                });
            }
        }
    },
}
</script>


<template>
    <div>
        <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
            <h4 class="font-weight-bold text-dark" v-b-toggle="'building-details-panel'">
                <i class="fas fa-list-alt fa-md text-primary"></i>
                <span class="ml-2" style="vertical-align: center">Building Details</span>

                <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
                <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
            </h4>

            <b-collapse id="building-details-panel" v-model="isExpanded"
                @input="(val) => SET_EXPANDED({ section: 'buildingDetails', isExpanded: val })">
                <div v-if="hasAsset">
                    <!-- ROW 1 -->
                    <div class="form-group row mb-3">
                        <label class="col-2 col-form-label text-right">Strata Plan Number<span
                                class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="max:255|required" name="K_PolicyNumber" v-slot="{ errors }">
                                <input class="form-control" maxlength="255" v-model="asset.K_PlanNumber"
                                    :disabled="effectiveReadOnly" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <label class="col-2 col-form-label text-right">Building Type<span
                                class="text-danger">*</span></label>
                        <div class="col-2">
                            <ValidationProvider rules="required" name="K_BuildingType" v-slot="{ errors }">
                                <b-form-select class="form-control" v-model="asset.K_BuildingType"
                                    :disabled="effectiveReadOnly">
                                    <option :value="null">-- Select --</option>
                                    <option value="Residential">Residential</option>
                                    <option value="Commercial">Commercial</option>
                                    <option value="Mixed Use">Mixed Use</option>
                                    <option value="Industrial">Industrial</option>
                                    <option value="Neighbourhood Association">Neighbourhood Association</option>
                                    <option value="Community Association">Community Association</option>
                                    <option value="Company Title">Company Title</option>
                                    <option value="Tenants in Common">Tenants in Common</option>
                                </b-form-select>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <!-- ROW 2 -->
                    <div class="form-group row mb-3">
                        <label class="col-2 col-form-label text-right">Address<span class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="max:255|required" name="K_Address" v-slot="{ errors }">
                                <input class="form-control" maxlength="255" v-model="asset.K_Address"
                                    :disabled="effectiveReadOnly" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <label class="col-2 col-form-label text-right">Suburb<span class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="max:255|required" name="K_Suburb" v-slot="{ errors }">
                                <input class="form-control" maxlength="255" v-model="asset.K_Suburb"
                                    :disabled="effectiveReadOnly" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <!-- ROW 3 -->
                    <div class="form-group row mb-3">
                        <label class="col-2 col-form-label text-right">State<span class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="required" name="K_State" v-slot="{ errors }">
                                <b-form-select class="form-control" v-model="asset.K_State" :disabled="isReadOnly">
                                    <option :value="null">-- Select --</option>
                                    <option value="NSW">NSW</option>
                                    <option value="ACT">ACT</option>
                                    <option value="QLD">QLD</option>
                                    <option value="VIC">VIC</option>
                                    <option value="SA">SA</option>
                                    <option value="WA">WA</option>
                                    <option value="NT">NT</option>
                                    <option value="TAS">TAS</option>
                                </b-form-select>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <label class="col-2 col-form-label text-right">Postcode<span class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="max:4|required" name="K_Postcode" v-slot="{ errors }">
                                <input class="form-control" maxlength="4" v-model="asset.K_Postcode"
                                    :disabled="effectiveReadOnly" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="form-group row mb-3" v-if="asset.K_PlanNumber !== ''">
                        <div class="col-6"></div>
                        <label class="col-2 col-form-label text-right" v-if="asset.K_PlanNumber !== ''">Building
                            Name<span class="text-danger">*</span></label>
                        <div class="col-4" v-if="asset.K_PlanNumber !== ''">
                            <ValidationProvider rules="max:255|required" name="BuildingName" v-slot="{ errors }">
                                <input class="form-control bg-gray-100" maxlength="255" v-model="asset.K_PlanNumber"
                                    :disabled="effectiveReadOnly" @change="handleAssetChange" />
                                <small>Update if you need to override the name for the Building record</small>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </b-card>
    </div>
</template>
