<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Instructions from "../../../common/Instructions.vue";
import PolicyDetails from "./PolicyDetails.vue";
import BuildingDetails from "./BuildingDetails.vue";

export default {
    name: "NewPolicyPage",

    components: {
        Instructions,
        PolicyDetails,
        BuildingDetails
    },

    props: {
        refData: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            declarationRequired: 'no'
        };
    },

    computed: {
        ...mapState('newBusiness', {
            transaction: state => state.transaction,
            asset: state => state.asset,
            policy: state => state.policy,
            isSaving: state => state.isSaving,
            isReadOnly: state => state.isReadOnly
        }),

        isWizardReadOnly() {
            return this.transaction?.K_Status === "Completed";
        },

        appData() {
            return {
                transaction: this.transaction,
                policy: this.policy,
                asset: this.asset
            };
        },

        hasBrokerCompany() {
            return !!this.asset?.K_BrokerCompany;
        },

        selectedBrokerCompany: {
            get() {
                return this.asset.K_BrokerCompany;
            },
            set(value) {
                console.log('Broker company changing to:', value);
                this.handleBrokerCompanyChange(value);
            }
        },

        instructions: function () {
            return "Please enter the general details relating to the policy and building below.";
        },
    },

    methods: {
        ...mapActions('newBusiness', [
            'saveNewBusinessData'
        ]),
        ...mapMutations('newBusiness', [
            'SET_TRANSACTION',
            'SET_ASSET',
            'SET_POLICY',
            'SET_DECLARATION_MODE'
        ]),

        async handleDeclarationChange(value) {
            this.SET_TRANSACTION({
                ...this.transaction,
                K_DeclarationRequired: value
            });

            // Set declaration mode
            this.SET_DECLARATION_MODE(value === 'no' ? 'manual' : 'form');

            await this.saveNewBusinessData();
        },

        async handleBrokerCompanyChange(value) {
            console.log('handleBrokerCompanyChange called with:', value);

            // Update both asset and transaction
            this.SET_ASSET({
                ...this.asset,
                K_BrokerCompany: value
            });

            this.SET_TRANSACTION({
                ...this.transaction,
                K_BrokerCompany: value
            });

            // Update root store broker company
            this.$store.commit('setBrokerCompany', value);

            console.log('After updates:', {
                assetBrokerCompany: this.asset.K_BrokerCompany,
                transactionBrokerCompany: this.transaction.K_BrokerCompany,
                rootBrokerCompany: this.$store.state.K_BrokerCompany
            });

            // Save changes to CRM
            await this.saveNewBusinessData();
        },

        // Original save changes method, now using store
        async saveChanges() {
            // Validate first
            const isValid = await this.$refs.wizardPage1.validate();
            if (!isValid) return;

            try {
                // Start full page loading
                this.$root.$emit('startPageLoading');

                await this.saveNewBusinessData();

                // Handle successful save
                this.$emit('updateWizardTitle');

                // Update status flags if needed (via action)
                if (this.transaction?.statusFlags &&
                    !this.transaction.statusFlags.policyDetailsEntered) {
                    await this.$emit('updateServerTransactionStatus', 'policyDetailsEntered');
                }
            } catch (error) {
                // Handle error
                this.$emit('handleError', error);
            }
        },

        // Methods to handle emitted events from child components
        handleError(error) {
            this.$emit('handleError', error);
        },

        updateWizardTitle() {
            const policyName = this.policy?.K_PolicyNumber || this.policy?.Name || 'Draft Policy';
            const type = 'Take-Up';  // Since this is always Take-Up for new business
            const title = `${type} - ${policyName}`;
            this.$store.commit("setWizardTitle", title);
        },

        updateServerTransactionStatus(status) {
            this.$emit('updateServerTransactionStatus', status);
        }
    },

    created() {
        // Initialize broker company if needed
        if (!this.asset.K_BrokerCompany && this.$store.state.K_BrokerCompany) {
            this.SET_ASSET({
                ...this.asset,
                K_BrokerCompany: this.$store.state.K_BrokerCompany
            });
        }

        // Initialize transaction type if needed
        if (!this.transaction.K_Type) {
            this.SET_TRANSACTION({
                ...this.transaction,
                K_Type: 'Take-Up'
            });
        }
    },

    // ... (previous code remains the same until the watch section)
    watch: {
        transaction: {
            immediate: false,
            deep: true,
            handler(newVal) {
                console.log('Transaction changed:', newVal);
            }
        }
    }

}

</script>



<template>
    <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
        <div>
            <!-- Instructions component -->
            <instructions :instructions-text="instructions" />

            <!-- Broker Company Selection -->
            <div class="mt-10">
                <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
                    <h4 class="font-weight-bold text-dark">
                        <i class="fa-solid fa-filter fa-md text-primary"></i>
                        <span class="ml-2" style="vertical-align: center">Broker Company</span>
                    </h4>
                    <div class="col-3 mb-2">
                        <ValidationProvider rules="required" name="K_BrokerCompany" v-slot="{ errors }">
                            <b-form-select v-model="selectedBrokerCompany" :disabled="isReadOnly || isWizardReadOnly"
                                class="form-control mt-2">
                                <option :value="null">-- Select --</option>
                                <option value="Collective Insurance Brokers">Collective Insurance Brokers</option>
                                <option value="Body Corporate Brokers">Body Corporate Brokers</option>
                            </b-form-select>
                        </ValidationProvider>
                    </div>
                </b-card>
            </div>

            <!-- Show fields when we have a Broker Company selected -->
            <div v-if="hasBrokerCompany">

                <!-- Child Components -->
                <policy-details :ref-data="refData" :is-read-only="isReadOnly || isWizardReadOnly"
                    @save-changes="saveChanges" @handle-error="handleError" @update-wizard-title="updateWizardTitle"
                    @update-server-transaction-status="updateServerTransactionStatus" />

                <building-details :ref-data="refData" :is-read-only="isReadOnly || isWizardReadOnly"
                    @save-changes="saveChanges" @handle-error="handleError" />

                <!-- Save Changes Button -->
                <div class="d-flex justify-content-end mt-5">
                    <b-button class="btn btn-primary" :disabled="isReadOnly || isWizardReadOnly || isSaving"
                        @click="saveChanges">
                        <b-spinner small v-if="isSaving" class="mr-1"></b-spinner>
                        <i class="fas fa-floppy-disk"></i> Save Changes
                    </b-button>
                </div>

                <!-- Declaration Form Option -->
                <div class="mt-10">
                    <h4>How do you wish to enter/obtain the building details?</h4>

                    <ValidationProvider name="declarationRequired" v-slot="{ errors }">
                        <v-radio-group v-model="declarationRequired" @change="handleDeclarationChange">
                            <v-radio label="I want to send a Declaration Form to the Strata Manager" value="yes"
                                class="switch-radio" ::is-read-only="isReadOnly || isWizardReadOnly" />
                            <v-radio label="I will enter these manually" value="no" class="switch-radio"
                                :disabled="isReadOnly || isWizardReadOnly" />
                        </v-radio-group>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

// ... (rest of the code remains the same)
