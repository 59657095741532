<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
    name: "PolicyDetails",

    props: {
        refData: {
            type: Object,
            required: true
        },
        isReadOnly: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loadingContacts: false,
            strataCompanyContacts: [],
        };
    },

    created() {
        // Force set the type if not set
        if (!this.transaction?.K_Type) {
            this.SET_TRANSACTION({
                ...this.transaction,
                K_Type: 'Take-Up'
            });
        }
    },

    computed: {
        ...mapState('newBusiness', {
            policy: state => state.policy,
            transaction: state => state.transaction,
            asset: state => state.asset,
            storeIsReadOnly: state => state.isReadOnly,
            isExpanded: state => state.isExpanded.policyDetails
        }),

        effectiveReadOnly() {
            return this.storeIsReadOnly || this.isReadOnly;
        },

        policyNumber: {
            get() {
                return this.policy.K_PolicyNumber;
            },
            set(value) {
                if (!value) return;
                this.SET_POLICY({
                    ...this.policy,
                    K_PolicyNumber: value,
                    Name: value
                });
                this.$emit('updateWizardTitle');
                // this.updateWizardTitle();
            }
        },

        policyName: {
            get() {
                return this.policy.Name;
            },
            set(value) {
                if (!value) return;
                this.SET_POLICY({
                    ...this.policy,
                    Name: value,
                    K_PolicyNumber: value
                });
            }
        },

        isExpanded: {
            get() {
                return this.$store.state.newBusiness.isExpanded.policyDetails;
            },
            set(value) {
                this.SET_EXPANDED({
                    section: 'policyDetails',
                    isExpanded: value
                });
            }
        },

        transactionType() {
            return this.transaction?.K_Type;
        },

        selectedContact: {
            get() {
                return this.policy.K_PrimaryPolicyContact;
            },
            set(value) {
                this.SET_POLICY({
                    ...this.policy,
                    K_PrimaryPolicyContact: value
                });
            }
        },

        selectedInsurer: {
            get() {
                return this.policy.K_Insurer;
            },
            set(value) {
                this.SET_POLICY({
                    ...this.policy,
                    K_Insurer: value
                });
            }
        },

        availableStrataCompanies() {
            if (!this.refData?.allStrataCompanies) return [];

            return this.refData.allStrataCompanies.map(sc => ({
                id: sc.id,
                Name: sc.Name
            }));
        },

        availableInsurers() {
            let insurers = [];
            if (this.refData?.allInsurers) {
                insurers = this.refData.allInsurers.map(insurer => ({
                    id: insurer.id,
                    Name: insurer.Name,
                    K_Panel: insurer.K_Panel
                }));
            }

            if (this.policy?.K_Insurer?.id &&
                !insurers.find(i => i.id === this.policy.K_Insurer.id)) {
                insurers.push(this.policy.K_Insurer);
            }
            return insurers;
        },

        availableProducts() {
            let products = [];
            if (this.refData?.allProducts) {
                products = this.refData.allProducts.map(product => ({
                    id: product.id,
                    Name: product.Name
                }));
            }

            if (this.policy?.K_Product?.id &&
                !products.find(p => p.id === this.policy.K_Product.id)) {
                products.push(this.policy.K_Product);
            }
            return products;
        },

        availableContacts() {
            // If we have contacts loaded, return those
            if (this.strataCompanyContacts.length > 0) {
                return this.strataCompanyContacts;
            }

            // Otherwise, if we have a selected contact, return just that one
            if (this.policy?.K_PrimaryPolicyContact) {
                return [this.policy.K_PrimaryPolicyContact];
            }

            return [];
        }
    },

    methods: {
        ...mapActions('newBusiness', [
            'saveNewBusinessData'
        ]),
        ...mapMutations('newBusiness', [
            'SET_POLICY',
            'SET_TRANSACTION',
            'SET_EXPANDED'
        ]),

        handlePolicyNumberChange(value) {
            if (!value) return;
            this.policyNumber = value;
        },

        async handleContactsFocus() {
            // Only load contacts if needed
            if (this.transaction.K_StrataCompany?.id && this.strataCompanyContacts.length === 0) {
                await this.loadContacts(this.transaction.K_StrataCompany.id);
            }
        },

        async handleStrataCompanySelection(strataCompany) {
            // Keep current policy state while updating contact
            const currentPolicy = { ...this.policy };

            // Only update the contact field
            this.SET_POLICY({
                ...currentPolicy,
                K_PrimaryPolicyContact: null
            });

            if (!strataCompany) {
                this.strataCompanyContacts = [];
                return;
            }

            this.SET_TRANSACTION({
                ...this.transaction,
                K_StrataCompany: strataCompany
            });

            this.strataCompanyContacts = [];

            if (strataCompany.id) {
                await this.loadContacts(strataCompany.id);
            }
        },

        async loadContacts(strataCompanyId) {
            this.loadingContacts = true;
            try {
                const response = await this.$newBusinessDataService.getStrataCompanyContacts(strataCompanyId);
                if (response?.data?.result) {
                    this.strataCompanyContacts = response.data.result.map(contact => ({
                        id: contact.id,
                        Name: contact.Name,
                        Email: contact.Email,
                        K_Phone: contact.K_Phone
                    })).sort((a, b) => (a.Name || '').localeCompare(b.Name || ''));
                }
            } catch (error) {
                console.error('Error fetching contacts:', error);
                this.$emit('handleError', error);
            } finally {
                this.loadingContacts = false;
            }
        },

        handleFromDateChange(value) {
            if (!value) return;

            this.SET_TRANSACTION({
                ...this.transaction,
                K_FromDate: value,
                // If ToDate isn't set, calculate it as FromDate + 12 months
                K_ToDate: !this.transaction.K_ToDate ?
                    moment(value).add(12, 'months').format('YYYY-MM-DD') :
                    this.transaction.K_ToDate
            });
        },
    },

    // watch: {
    //     'policy.K_PolicyNumber': {
    //         immediate: true,
    //         handler(newValue) {
    //             if (newValue) {
    //                 this.updateWizardTitle();
    //             }
    //         }
    //     }
    // },

}
</script>

<style>
.form-control:disabled,
.form-control[readonly] {
    color: #3f4254;
    cursor: not-allowed;
    background-color: #f3f6f9;
}

.custom-autocomplete {
    font-size: 13px !important;
}

.custom-autocomplete .v-select__selection {
    font-size: 14px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
}

.b-form-btn-label-control.form-control[aria-disabled="true"],
.b-form-btn-label-control.form-control[aria-readonly="true"] {
    background-color: #f3f6f9;
    cursor: not-allowed;
}
</style>

<template>
    <div>
        <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
            <h4 class="font-weight-bold text-dark" v-b-toggle="'policy-details-panel'">
                <i class="fas fa-list-alt fa-md text-primary"></i>
                <span class="ml-2" style="vertical-align: center">Policy Details</span>

                <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
                <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
            </h4>

            <b-collapse id="policy-details-panel" v-model="isExpanded">
                <!-- ROW 1 -->
                <div class="form-group row mb-3">
                    <label v-if="transaction?.K_Type === 'Take-Up'" class="col-2 col-form-label text-right">Policy
                        Number<span class="text-danger">*</span></label>
                    <div class="col-4">
                        <ValidationProvider
                            v-if="transaction?.K_Type === 'Take-Up' || transaction?.K_Type === 'New Business'"
                            rules="required|max:255" name="K_PolicyNumber" v-slot="{ errors }">
                            <input class="form-control" maxlength="255" :value="policyNumber"
                                @input="handlePolicyNumberChange($event.target.value)" :disabled="effectiveReadOnly" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <label class="col-2 col-form-label text-right">Policy Owner<span class="text-danger">*</span></label>
                    <div class="col-4">
                        <ValidationProvider rules="required" name="Owner" v-slot="{ errors }">
                            <v-autocomplete v-model="policy.Owner" :items="refData.activeUsers" item-text="full_name"
                                item-value="id" :disabled="effectiveReadOnly" dense clearable
                                :label="policy.Owner ? '' : 'Select Owner'" hint="Start typing to search users"
                                return-object class="custom-autocomplete" :menu-props="{ offsetY: true }" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>

                <!-- ROW 2 -->
                <div class="form-group row mb-3">
                    <label class="col-2 col-form-label text-right">Strata Company<span class="text-danger">*</span></label>
                    <div class="col-4">

                        <ValidationProvider rules="required" name="K_StrataCompany" v-slot="{ errors }">
                            <v-autocomplete v-model="transaction.K_StrataCompany" :items="availableStrataCompanies"
                                item-text="Name" item-value="id" :disabled="effectiveReadOnly" dense clearable
                                :label="transaction.K_StrataCompany ? '' : 'Select Strata Company'"
                                @change="handleStrataCompanySelection" hint="Start typing to search strata companies"
                                return-object class="custom-autocomplete" :menu-props="{ offsetY: true }" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <label v-if="transaction.K_StrataCompany" class="col-2 col-form-label text-right">Primary
                        Policy Contact<span class="text-danger">*</span>
                    </label>
                    <div class="col-4" v-if="transaction.K_StrataCompany">
                        <ValidationProvider rules="required" name="K_PrimaryPolicyContact" v-slot="{ errors }">
                            <v-autocomplete v-model="selectedContact" :items="availableContacts" item-text="Name"
                                item-value="id" :disabled="effectiveReadOnly || !transaction.K_StrataCompany"
                                :loading="loadingContacts" dense clearable :label="selectedContact ? '' : 'Select Contact'"
                                hint="Start typing to search contacts" return-object class="custom-autocomplete"
                                :menu-props="{ offsetY: true }"
                                :no-data-text="loadingContacts ? 'Loading contacts...' : 'No contacts found'"
                                @focus="handleContactsFocus">
                                <template v-slot:selection="{ item }">
                                    <div class="custom-chip mb-1">
                                        {{ item.Name }}
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.Name }}</v-list-item-title>
                                        <v-list-item-subtitle v-if="item.Email">
                                            <i class="fa-solid fa-envelope fa-sm mr-1"></i>{{ item.Email }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.K_Phone">
                                            <i class="fa-solid fa-phone fa-sm mr-1"></i>{{ item.K_Phone }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>

                <!-- ROW 3 -->
                <div class="form-group row mb-3">
                    <label class="col-2 col-form-label text-right">Policy From Date<span
                            class="text-danger">*</span></label>
                    <div class="col-4">
                        <ValidationProvider rules="required" name="K_FromDate" v-slot="{ errors }">
                            <b-form-datepicker class="form-control" :locale="'en-AU'" v-model="transaction.K_FromDate"
                                @input="handleFromDateChange" :disabled="effectiveReadOnly" :date-format-options="{
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <label class="col-2 col-form-label text-right">Policy To Date<span class="text-danger">*</span></label>
                    <div class="col-4">
                        <ValidationProvider rules="required" name="K_ToDate" v-slot="{ errors }">
                            <b-form-datepicker class="form-control" :locale="'en-AU'" v-model="transaction.K_ToDate"
                                :disabled="effectiveReadOnly" :date-format-options="{
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>

                <!-- ROW 4 -->
                <div class="form-group row mb-3">
                    <label class="col-2 col-form-label text-right">Insurer<span class="text-danger">*</span></label>
                    <div class="col-4">
                        <ValidationProvider rules="required" name="K_Insurer" v-slot="{ errors }">
                            <v-autocomplete v-model="selectedInsurer" :items="availableInsurers" item-text="Name"
                                item-value="id" :disabled="effectiveReadOnly" dense clearable
                                :label="selectedInsurer ? '' : 'Select Insurer'" return-object class="custom-autocomplete"
                                :menu-props="{ offsetY: true }" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <label class="col-2 col-form-label text-right">Appointed Date<span class="text-danger">*</span></label>
                    <div class="col-4">
                        <ValidationProvider rules="required" name="K_AppointedDate" v-slot="{ errors }">
                            <b-form-datepicker class="form-control" :locale="'en-AU'" v-model="policy.K_AppointedDate"
                                :disabled="effectiveReadOnly" :date-format-options="{
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>

                <!-- ROW 5 -->
                <div class="form-group row mb-3">
                    <label class="col-2 col-form-label text-right">Product<span class="text-danger">*</span></label>
                    <div class="col-4">
                        <ValidationProvider rules="required" name="K_Product" v-slot="{ errors }">
                            <v-autocomplete v-model="policy.K_Product" :items="availableProducts" item-text="Name"
                                item-value="id" :disabled="effectiveReadOnly" dense clearable
                                :label="policy.K_Product ? '' : 'Select Product'" hint="Start typing to search products"
                                return-object class="custom-autocomplete" :menu-props="{ offsetY: true }" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <label class="col-2 col-form-label text-right" v-if="policy.K_PolicyNumber !== ''">Policy Name<span
                            class="text-danger">*</span></label>
                    <div class="col-4" v-if="policy.K_PolicyNumber !== ''">
                        <ValidationProvider rules="required|max:255" name="Name" v-slot="{ errors }">
                            <input class="form-control bg-gray-100" maxlength="255" v-model="policy.Name"
                                :disabled="effectiveReadOnly" @blur="$emit('updateWizardTitle')" />
                            <small>Update if you need to override the name for the Policy record</small>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                </div>


            </b-collapse>
        </b-card>
    </div>
</template>
