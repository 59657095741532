export default {
  state: {
    claims: [],
    endorsementsConditions: '',
    declarationFormData: null,
    declarationRequired: 'NO',
    declarationFormStatus: null,
    declarationFormSubmissionTime: null,
    declarationFormSubmittedBy: null,
    overridingBroker: null,
    declarationFormLink: null,
    overrideReason: null,
    declarationMode: 'manual',

    // Core data matching schema
    transaction: {
      K_Type: 'Take-Up',
      K_DeclarationRequired: 'no',
      K_BrokerCompany: null,
      K_Status: null,
      K_StatusDetails: null,
      K_TransactionDataAttachmentId: null,
      K_FromDate: null,
      K_ToDate: null,
    },
    policy: {
      Name: '',
      Owner: null,
      K_StrataCompany: null,
      K_PrimaryPolicyContact: null,
      K_FromDate: null,
      K_ToDate: null,
      K_Insurer: null,
      K_Product: null,
      K_PolicyNumber: null,
      K_AppointedDate: null,
      K_BrokerCompany: null,
      K_PlanNumber: null
    },
    asset: {
      Name: '',
      K_PlanNumber: '',
      K_BuildingType: null,
      K_Address: '',
      K_Suburb: '',
      K_State: null,
      K_Postcode: '',
      K_BrokerCompany: null
    },
    client: {
      Name: '',
      K_PlanNumber: '',
      K_BuildingType: null,
      K_Address: '',
      K_Suburb: '',
      K_State: null,
      K_Postcode: '',
      K_BrokerCompany: null
    },

    // UI state
    declarationMode: null,
    isSaving: false,
    currentStep: 1,
    lastBrokerCompany: null,
    isExpanded: {
      policyDetails: true,
      buildingDetails: true
    },

    declarationData: {
      sumInsuredData: [],
      excessData: [],
      assetPropertiesData: []
    },

    // Status flags from new-business-status-flags.json
    statusFlags: {
      processingStarted: false,
      policyDetailsEntered: false,
      declarationSent: false,
      declarationReceived: false,
      declarationDetailsConfirmed: false,
      quoteSlipSent: false,
      quotesUpdated: false,
      feesUpdated: false,
      quoteComparisonConfirmed: false,
      brokerAdviceSent: false,
      quoteAccepted: false,
      quoteAcceptanceSentToInsurer: false,
      nbLetterSent: false,
      readyForInvoicing: false,
      insightDataUpdated: false,
      invoiceSent: false,
      closingSent: false,
      cocSent: false,
      transactionFinalised: false
    },

    wizardTitle: ''
  },

  mutations: {
    SET_DECLARATION_DATA(state, data) {
      state.declarationData = data;
    },
    SET_OVERRIDE_REASON(state, reason) {
      state.overrideReason = reason;
    },
    CLEAR_OVERRIDE_REASON(state) {
      state.overrideReason = null;
    },
    UPDATE_CLAIMS(state, claims) {
      state.claims = claims;
    },
    SET_CLAIMS(state, claims) {
      state.claims = claims;
    },
    ADD_CLAIM(state) {
      state.claims?.push({
        K_Insurer: '',
        K_InsurerClaimNumber: '',
        K_DateOfLoss: null,
        K_Status: null,
        K_TotalClaimCost: null,
        K_DescriptionOfLossDamage: ''
      });
    },
    REMOVE_CLAIM(state, index) {
      state.claims?.splice(index, 1);
    },
    UPDATE_ENDORSEMENTS_CONDITIONS(state, value) {
      state.endorsementsConditions = value;
    },
    SET_DECLARATION_FORM_DATA(state, data) {
      state.declarationFormData = data;
    },
    SET_DECLARATION_REQUIRED(state, value) {
      state.declarationRequired = value;
    },
    SET_DECLARATION_MODE(state, mode) {
      state.declarationMode = mode;
      // Ensure transaction declaration required matches mode
      if (mode === 'manual' && state.transaction) {
        state.transaction.K_DeclarationRequired = 'NO';
      }
    },

    UPDATE_BROKER_COMPANY(state, company) {
      state.K_BrokerCompany = company;
      state.lastBrokerCompany = company;
      if (state.asset) {
        state.asset.K_BrokerCompany = company;
      }
      if (state.transaction) {
        state.transaction.K_BrokerCompany = company;
      }
    },

    UPDATE_STATUS_FLAG(state, { flag, value }) {
      state.statusFlags[flag] = value;
    },
    SET_STATUS_FLAGS(state, flags) {
      state.statusFlags = { ...flags };
    },
    SET_READONLY(state, value) {
      state.isReadOnly = value;
    },
    SET_VALIDATION_STATE(state, { field, errors }) {
      state.validationState[field] = errors;
    },
    CLEAR_VALIDATION_STATE(state) {
      state.validationState = {};
    },
    SET_SAVING(state, isSaving) {
      state.isSaving = isSaving;
    },
    SET_VALIDATION_ERRORS(state, errors) {
      state.validationErrors = errors;
    },
    SET_EXPANDED(state, { section, isExpanded }) {
      state.isExpanded[section] = isExpanded;
    },

    SET_TRANSACTION(state, transaction) {
      // Always force Take-Up when setting transaction
      state.transaction = {
        ...transaction,
        K_Type: 'Take-Up',  // Always force Take-Up
        // Ensure declaration required is uppercase
        K_DeclarationRequired: transaction.K_DeclarationRequired?.toUpperCase() || 'NO',
        K_TransactionDataAttachmentId: transaction.K_TransactionDataAttachmentId || state.transaction.K_TransactionDataAttachmentId
      };

      // If we need to update the title when transaction changes
      if (state.policy?.Name) {
        this.dispatch('updateWizardTitle', {
          type: 'Take-Up',
          policyName: state.policy.Name
        }, { root: true });
      }
    },

    // SET_POLICY(state, policy) {
    //   state.policy = policy || state.policy;
    // },

    SET_POLICY(state, policy) {
      state.policy = {
        ...state.policy,
        ...policy,
        // Ensure these values are preserved
        Name: policy?.K_PolicyNumber || policy?.Name || state.policy?.Name || 'Draft Policy',
        K_PolicyNumber: policy?.K_PolicyNumber || state.policy?.K_PolicyNumber
      };
    },

    SET_WIZARD_TITLE(state, title) {
      state.wizardTitle = title;
    },

    SET_ASSET(state, asset) {
      state.asset = asset || state.asset;
    },

    UPDATE_DECLARATION_DATA(state, data) {
      // Ensure we preserve existing data structure
      state.declarationData = {
        ...state.declarationData,
        // ...data,
        assetPropertiesData: state.declarationData.assetPropertiesData?.map((prop) => {
          if (prop.isDisclosure) {
            return {
              ...prop,
              value: prop.newValue || prop.value || 'No',
              children: prop.children?.map((child) => ({
                ...child,
                value: child.newValue || child.value || null,
              })),
            };
          }
          return prop;
        }),
      };
    }
  },

  actions: {
    async loadDeclarationData({ commit }) {
      try {
        const { data } = await this._vm.$newBusinessDataService.getWizardAppData();
        if (data.result?.declarationData) {
          commit('SET_DECLARATION_DATA', data.result.declarationData);
        }
      } catch (error) {
        console.error('Error loading declaration data:', error);
        throw error;
      }
    },

    async submitOverride({ state, commit }) {
      try {
        await this._vm.$newBusinessDataService.overrideDeclarationForm(state.overrideReason);
        commit('CLEAR_OVERRIDE_REASON');
        return true;
      } catch (error) {
        console.error('Error submitting override:', error);
        throw error;
      }
    },

    async saveClaims({ commit, state }) {
      try {
        await this.saveNewBusinessData();
        commit('SET_CLAIMS', state.claims);
      } catch (error) {
        console.error('Error saving claims:', error);
        throw error;
      }
    },

    async loadDeclarationFormData({ commit, state }) {
      try {
        const response = await this._vm.$newBusinessDataService.getDeclarationFormData();
        if (response?.data?.result) {
          commit('SET_DECLARATION_FORM_DATA', response.data.result);
        }
      } catch (error) {
        console.error('Error loading declaration form data:', error);
        throw error;
      }
    },

    async updateBrokerCompany({ commit, dispatch, state }, company) {
      commit('UPDATE_BROKER_COMPANY', company);
      try {
        await dispatch('saveNewBusinessData');
      } catch (error) {
        // Revert on failure
        commit('UPDATE_BROKER_COMPANY', state.lastBrokerCompany);
        throw error;
      }
    },

    async getWizardAppData({ commit, dispatch }) {
      try {
        const { data } = await this._vm.$newBusinessDataService.getWizardAppData();
        if (!data.result) throw new Error('Failed to get wizard data');

        const { transaction, policy, asset, statusFlags, claims = [] } = data.result;

        // Ensure transaction type is set
        const updatedTransaction = {
          ...transaction,
          K_Type: 'Take-Up'
        };

        commit('SET_TRANSACTION', transaction);
        commit('SET_POLICY', policy);
        commit('SET_ASSET', asset);
        commit('SET_STATUS_FLAGS', statusFlags);
        commit('UPDATE_CLAIMS', claims);

        // Update wizard title in root store
        if (policy?.Name) {
          dispatch('updateWizardTitle', {
            type: updatedTransaction.K_Type,
            policyName: policy.Name
          }, { root: true });
        }

        return {
          ...data.result,
          transaction: updatedTransaction
        };
      } catch (error) {
        console.error('Failed to get wizard data:', error);
        throw error;
      }
    },

    async saveNewBusinessData({ commit, state }) {
      commit('SET_SAVING', true);
      try {
        // Ensure transaction type is set before saving
        const transactionToSave = {
          ...state.transaction,
          K_Type: 'Take-Up',
          K_TransactionDataAttachmentId: state.transaction.K_TransactionDataAttachmentId
        };

        // Clean up claims data before sending
        const cleanClaims = state.claims?.map(claim => ({
          K_Insurer: claim.K_Insurer,
          K_InsurerClaimNumber: claim.K_InsurerClaimNumber,
          K_DateOfLoss: claim.K_DateOfLoss,
          K_Status: claim.K_Status,
          K_TotalClaimCost: claim.K_TotalClaimCost,
          K_DescriptionOfLossDamage: claim.K_DescriptionOfLossDamage
        }));

        // Include complete data in payload
        const payload = {
          transactionId: state.transaction?.id,
          transaction: transactionToSave,
          policy: {
            ...state.policy,
            K_EndorsementsConditions: state.endorsementsConditions
          },
          asset: state.asset,
          statusFlags: state.statusFlags,
          claims: cleanClaims,
          declarationData: {
            ...state.declarationData,
            assetPropertiesData: state.declarationData.assetPropertiesData?.map(prop => {
              if (prop.isDisclosure) {
                return {
                  ...prop,
                  newValue: prop.newValue || 'No',
                  children: prop.children?.map(child => ({
                    ...child,
                    newValue: child.newValue || null
                  }))
                };
              }
              return prop;
            })
          },
          declarationFormData: state.declarationFormData,
          declarationRequired: state.declarationRequired,
          endorsementsConditions: state.endorsementsConditions
        };

        // Send to server
        const { data } = await this._vm.$newBusinessDataService.saveWizardAppData(payload);
        if (!data.result) {
          throw new Error('No result returned from server');
        }

        // Update store with complete response data
        const result = data.result;
        const updatedTransaction = {
          ...result.transaction,
          K_Type: 'Take-Up',
          K_TransactionDataAttachmentId: data.result.transaction.K_TransactionDataAttachmentId
        };

        // Ensure policy name is properly set
        const updatedPolicy = {
          ...result.policy,
          Name: result.policy.K_PolicyNumber || result.policy.Name || 'Draft Policy'
        };

        commit('SET_TRANSACTION', updatedTransaction);
        commit('SET_POLICY', updatedPolicy);
        commit('SET_ASSET', result.asset);
        commit('SET_STATUS_FLAGS', result.statusFlags);
        commit('UPDATE_ENDORSEMENTS_CONDITIONS', result.endorsementsConditions);
        commit('UPDATE_DECLARATION_DATA', data.result.declarationData);

        // Clean up received claims data before committing to store
        if (data.result?.claims) {
          const cleanedClaims = data.result.claims.map(claim => ({
            K_Insurer: claim.K_Insurer,
            K_InsurerClaimNumber: claim.K_InsurerClaimNumber,
            K_DateOfLoss: claim.K_DateOfLoss,
            K_Status: claim.K_Status,
            K_TotalClaimCost: claim.K_TotalClaimCost,
            K_DescriptionOfLossDamage: claim.K_DescriptionOfLossDamage
          }));
          commit('SET_CLAIMS', cleanedClaims);
        }

        // Handle declaration data
        if (result.declarationData) {
          commit('SET_DECLARATION_DATA', result.declarationData);
        }

        // Handle declarationRequired
        if (result.declarationRequired) {
          commit('SET_DECLARATION_REQUIRED', result.declarationRequired);
        }

        // Handle endorsements/conditions
        if (result.policy?.K_EndorsementsConditions !== undefined) {
          commit('UPDATE_ENDORSEMENTS_CONDITIONS', result.policy.K_EndorsementsConditions);
        }

        // Update root store title
        const policyName = result.policy.K_PolicyNumber || result.policy.Name || 'Draft Policy';
        commit('setWizardTitle', `Take-Up - ${policyName}`, { root: true });

        return result;
      } catch (error) {
        console.error('Error saving wizard data:', error);
        throw error;
      } finally {
        commit('SET_SAVING', false);
      }
    }
  },

  getters: {
    assetData: state => state.asset,
    policyData: state => state.policy,
    transactionData: state => state.transaction,
    isSaving: state => state.isSaving,
    hasValidationErrors: state => Object.keys(state.validationErrors).length > 0,
    isFormValid: state => Object.keys(state.validationState).length === 0,
    currentStep: state => state.currentStep,

    isClaimLodged: state => state.claimLodged,
    isInsurerNotified: state => state.insurerNotified,
    claimFormLink: (state) => {
      return state.insurerNotified || !state.claimFormInternalLink
        ? state.claimFormViewLink
        : state.claimFormInternalLink;
    },
    quoteBeingUpdated: state => state.quoteBeingUpdated,
    appData: state => state.appData,
    getBrandLogo: state => {
      return state.brokerLogos[state.K_BrokerCompany] || state.brokerLogos['Collective Insurance Brokers']; // default to CIB
    },

    declarationStatus: (state) => {
      const timestamp = state.declarationFormSubmissionTime;
      const formattedTime = timestamp ?
        moment.tz(timestamp, "Australia/Sydney").format("DD/MM/YYYY hh:mm:ss A") :
        null;

      if (!formattedTime) {
        return 'Awaiting Declaration Form from the Strata contact.';
      }

      if (state.declarationFormStatus === 'Overridden') {
        let status = `<span class="text-danger">Declaration Form was overridden by ${state.overridingBroker || 'Broker'} on ${formattedTime}.</span>`;
        if (state.overrideReason) {
          status += `<br/>Reason: ${state.overrideReason}`;
        }
        return status;
      }

      return `<span class="text-primary">Declaration Form was submitted by ${state.declarationFormSubmittedBy || 'Strata Contact'} on ${formattedTime}.</span>`;
    },

    declarationFinalised: (state) => {
      return state.declarationFormStatus === "Submitted" ||
        state.declarationFormStatus === "Overridden";
    },

    declarationOverridden: (state) => {
      return state.declarationFormStatus === "Overridden";
    },
    isManualMode: state => state.declarationMode === 'manual',
    isFormMode: state => state.declarationMode === 'form',
    getClaims: state => state.appData.claims || []
  }
};
