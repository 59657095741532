<template>
    <ValidationObserver ref="newbusinessWizard">
        <div class="card card-custom">
            <div class="card-body p-0">
                <!--begin: Wizard-->
                <div class="wizard wizard-3" id="newbusiness_wizard" data-wizard-state="step-first"
                    data-wizard-clickable="true">
                    <!--begin: Wizard Nav -->
                    <div class="wizard-nav border-bottom mb-1 mb-lg-5">
                        <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                            <div v-for="(wizardPage, index) in wizardPages" :key="index" class="wizard-step"
                                style="cursor: default" data-wizard-type="step">
                                <div class="wizard-label">
                                    <h3 class="wizard-title">
                                        <span>{{ index + 1 }}</span>{{ wizardPage.title }}
                                    </h3>
                                    <div class="wizard-bar"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Nav -->
                    <!--begin: Wizard Body-->
                    <div class="card card-custom card-shadowless rounded-top-0">
                        <div class="card-body p-0">
                            <div class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10">
                                <div class="col-xl-12 col-xxl-10">
                                    <!--begin: Wizard Form-->
                                    <div class="form" id="newbusiness_wizard_form">
                                        <!-- ERROR ALERTS -->
                                        <b-alert v-if="errorAlert" variant="danger" show
                                            class="error-alert mb-4 position-relative text-white"
                                            style="z-index: 1000; background-color: #dc3545;">
                                            <div class="d-flex align-items-center">
                                                <i class="fas fa-exclamation-triangle mr-2"></i>
                                                <div class="text-light" v-html="errorAlert"></div>
                                            </div>
                                        </b-alert>
                                        <!--begin: Wizard Page 1-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <new-policy-page :ref-data="referenceData"
                                                    :is-read-only="isReadOnly || acceptedQuoteExists"
                                                    @save-changes="handleSaveChanges"
                                                    @updateWizardTitle="setHeaderInformation"
                                                    @updateServerTransactionStatus="updateServerTransactionStatus"
                                                    @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 1-->

                                        <!--begin: Wizard Page 2-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <declaration-page :app-data="wizardAppData" :ref-data="referenceData"
                                                    :is-read-only="isWizardReadOnly || acceptedQuoteExists
                                                        " @updateServerTransactionStatus="updateServerTransactionStatus
        " @updateWizardAppDataString="updateWizardAppDataString"
                                                    :get-client-declaration-email-template="getClientDeclarationEmailTemplate"
                                                    @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 2-->

                                        <!--begin: Wizard Page 3-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData">
                                                <review-page :app-data="wizardAppData" :is-read-only="isWizardReadOnly || acceptedQuoteExists
                                                    " @overrideDeclaration="overrideDeclarationForm"
                                                    @updateServerTransactionStatus="updateServerTransactionStatus
                                                        " @updateWizardAppDataString="updateWizardAppDataString"
                                                    @handleError="handleError" @save-changes="handleSaveChanges"
                                                    @alertSaved="handleAlertSaved" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 3-->

                                        <!--begin: Wizard Page 4-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <insurers-page :app-data="wizardAppData" :ref-data="referenceData"
                                                    :is-read-only="isWizardReadOnly || acceptedQuoteExists
                                                        " @updateServerTransactionStatus="updateServerTransactionStatus
        " @updateWizardAppDataString="updateWizardAppDataString" @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 4-->
                                        <!--begin: Wizard Page 5-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData">
                                                <quotes-page :app-data="wizardAppData" :manager-users="managerUsers"
                                                    :is-current-user-manager="isCurrentUserManager" :is-read-only="isWizardReadOnly || acceptedQuoteExists
                                                        " @saveQuote="saveQuoteToServer"
                                                    @setRecommendedQuote="setRecommendedQuote"
                                                    @applyBrokerFeeToAllQuotes="applyBrokerFeeToAllQuotes"
                                                    @createQuoteComparisonTable="createQuoteComparisonTable
                                                        " @statusUpdated="updateUiTransactionStatus"
                                                    @requestBrokerAdviceReview="requestBrokerAdviceReview"
                                                    @approveBrokerAdvice="approveOrRejectBrokerAdvice"
                                                    @rejectBrokerAdvice="approveOrRejectBrokerAdvice"
                                                    @updateServerTransactionStatus="updateServerTransactionStatus
                                                        " @updateWizardAppDataString="updateWizardAppDataString"
                                                    @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 5-->
                                        <!--begin: Wizard Page 6-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData">
                                                <finalisation-page :app-data="wizardAppData"
                                                    :is-read-only="isWizardReadOnly" @acceptQuote="acceptQuote"
                                                    @statusUpdated="updateUiTransactionStatus"
                                                    @updateServerTransactionStatus="updateServerTransactionStatus
                                                        " @updateWizardAppDataString="updateWizardAppDataString"
                                                    @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 6-->
                                        <!--begin: Wizard Page 7-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData">
                                                <invoice-page :app-data="wizardAppData"
                                                    @statusUpdated="updateUiTransactionStatus"
                                                    :is-read-only="isWizardReadOnly" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 7-->

                                        <!--begin: Wizard Actions -->
                                        <div class="d-flex justify-content-between border-top pt-10">
                                            <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                                v-show="wizardAppData && !isFirstPage" @click.prevent="goToPreviousPage">
                                                Previous Step
                                            </button>
                                            <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                                v-show="wizardAppData && !isLastPage && !showFinaliseButton
                                                    " @click.prevent="goToNextPage">
                                                Next Step
                                            </button>
                                            <button class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                                                v-show="showFinaliseButton" @click.prevent="finaliseTransaction">
                                                Finalise Transaction
                                            </button>
                                        </div>
                                        <!--end: Wizard Actions -->
                                    </div>
                                    <!--end: Wizard Form-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Body-->
                </div>
                <!--end: Wizard-->
            </div>
        </div>
    </ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.switch-radio .v-input--selection-controls__ripple {
    margin: 2px 0;
    height: 24px;
    width: 40px;
    border-radius: 12px;
    position: absolute;
    display: inline-block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.switch-radio .v-input--selection-controls__ripple:before {
    border: 2px solid #95979d;
    background-color: transparent;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: unset;
}

.switch-radio .v-input--selection-controls__ripple:after {
    content: "\f108";
    height: 20px;
    width: 20px;
    top: 2px;
    left: 0px;
    bottom: 2px;
    margin-left: 2px;
    font-size: 0.55em;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    border-radius: 50%;
    background: #ebedf3;
    transition: all 0.5s;
    font-family: ki;
    color: #ebedf3;
    line-height: 2;
}

.switch-radio input:empty~span:before {
    border: 2px solid #ebedf3;
    background-color: transparent;
    transform: unset;
}

.switch-radio input:checked~.v-input--selection-controls__ripple:after {
    left: 16px;
    background: #3699ff;
}

.switch-radio .v-input--selection-controls__input {
    width: 50px;
}

.switch-radio .v-input--selection-controls__input i {
    display: none;
}

.switch-radio input:checked~.v-input--selection-controls__ripple:before {
    border-color: #3699ff;
    opacity: 1;
    background-color: transparent;
}

.switch-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
    background-color: transparent;
    transform: unset;
}

.switch-radio .v-ripple__container {
    display: none;
}

.switch-radio label {
    margin-bottom: 0;
}

.error-alert {
    margin: 1rem;
    padding: 1rem;
    border-left: 5px solid #dc3545;
    background-color: #fff;
    color: white !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    &.b-alert {
        font-size: 1rem;
        font-weight: 500;
    }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import _ from "lodash";
import stringify from "fast-json-stable-stringify";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

import NewPolicyPage from "./pages/1-NewPolicy/NewPolicyPage.vue";
import DeclarationPage from "./pages/2-Declaration/DeclarationPage.vue";
import ReviewPage from "./pages/3-Review/ReviewPage.vue";
import InsurersPage from "./pages/4-Insurers/InsurersPage.vue";
import QuotesPage from "./pages/5-Quotes/QuotesPage.vue";
import FinalisationPage from "./pages/6-Finalisation/FinalisationPage.vue";
import invoicePage from "./pages/7-Invoice/InvoicePage.vue";
import Swal from "sweetalert2";

export default {
    name: "NewBusinessWizard",
    components: {
        NewPolicyPage,
        DeclarationPage,
        ReviewPage,
        InsurersPage,
        QuotesPage,
        FinalisationPage,
        invoicePage,
    },
    data() {
        return {
            wizardPages: [
                {
                    id: "wizardPage1",
                    title: "New Policy",
                    subtitle: "New Policy",
                },
                {
                    id: "wizardPage2",
                    title: "Declaration",
                    subtitle: "Declaration",
                },
                {
                    id: "wizardPage3",
                    title: "Review",
                    subtitle: "Review",
                },
                {
                    id: "wizardPage4",
                    title: "Insurers",
                    subtitle: "Insurers",
                },
                {
                    id: "wizardPage5",
                    title: "Quotes",
                    subtitle: "Quotes",
                },
                {
                    id: "wizardPage6",
                    title: "Finalisation",
                    subtitle: "Finalisation",
                },
                {
                    id: "wizardPage7",
                    title: "Invoice",
                    subtitle: "Invoice",
                },
            ],

            userId: null,
            wizardObj: null,
            errorAlert: null,
            wizardAppData: null,
            referenceData: null,
            appDataString: null,
        };
    },

    created() {
        // Set transaction type if not set
        if (!this.transaction?.K_Type) {
            this.SET_TRANSACTION({
                ...this.transaction,
                K_Type: 'Take-Up'
            });
        }

        // Ensure declaration required is set to NO by default
        if (!this.transaction?.K_DeclarationRequired) {
            this.SET_TRANSACTION({
                ...this.transaction,
                K_DeclarationRequired: 'NO'
            });
        }

        // Ensure declaration mode is manual by default
        this.SET_DECLARATION_MODE('manual');
    },

    mounted() {
        // Show page loading
        this.startPageLoading();

        // Extract information from URL params
        let queryParams = this.$route.query;
        if (
            !queryParams.userId ||
            !queryParams.transactionId ||
            !queryParams.token
        ) {
            this.handleError("Unauthorised access!");
            return;
        }

        this.userId = queryParams.userId;

        // Initialise NewBusinessDataService with the token
        this.$newBusinessDataService.init(
            queryParams.transactionId,
            queryParams.userId,
            queryParams.token
        );

        // Initialise CommonDataService with the token
        this.$commonDataService.init(
            queryParams.transactionId,
            queryParams.userId,
            queryParams.token
        );

        // Initialise the UI wizard
        this.initWizard();

        // Get Reference Data and Application Data
        Promise.all([
            this.getReferenceData(),
            this.getWizardAppData()
        ]).then(() => {
            this.stopPageLoading();
            this.setTransactionBoxLink();
        }).catch(error => {
            this.handleError(error);
            this.stopPageLoading();
        });
    },

    computed: {
        ...mapState('newBusiness', {
            transaction: state => state.transaction,
            policy: state => state.policy,
            asset: state => state.asset,
            isSaving: state => state.isSaving,
            isReadOnly: state => state.isReadOnly,
            statusFlags: state => state.statusFlags
        }),

        isWizardReadOnly() {
            return this.transaction?.K_Status === "Completed";
        },

        declarationRequired: {
            get() {
                return this.transaction?.K_DeclarationRequired || 'no';
            },
            set(value) {
                this.handleDeclarationChange(value);
            }
        },

        managerUsers: function () {
            let managerUsers = [];
            if (Array.isArray(this.users)) {
                // Remove current user from manager-users (shouldn't be able to ask for own approval)
                managerUsers = _.filter(this.users, (user) => {
                    return user.id !== this.userId && user.K_ManagerReviewer === true;
                });
            }
            return managerUsers;
        },

        isCurrentUserManager: function () {
            if (Array.isArray(this.users)) {
                let currentUser = _.find(this.users, { id: this.userId });
                if (currentUser) {
                    return !!currentUser.K_ManagerReviewer;
                }
            }
            return false;
        },

        acceptedQuoteExists() {
            return !!this.wizardAppData?.acceptedQuote?.id;
        },

        isLastPage: function () {
            if (this.wizardObj && this.wizardAppData) {
                let txnType = this.wizardAppData.transaction.K_Type;
                if (txnType === "Take-Up") {
                    return this.wizardObj.getStep() === 3;
                } else {
                    return this.wizardObj.isLastStep();
                }
            }
            return false;
        },

        isFirstPage: function () {
            return this.wizardObj && this.wizardObj.isFirstStep();
        },

        showFinaliseButton: function () {
            return (
                this.wizardAppData &&
                !this.isWizardReadOnly &&
                ((this.wizardAppData.transaction.K_Type === "New Business" &&
                    this.isLastPage) ||
                    (this.wizardAppData.transaction.K_Type === "Take-Up" &&
                        this.wizardObj.getStep() === 3 && this.wizardAppData?.transaction?.K_Status !== "Completed")
                ));
        },
    },

    methods: {
        ...mapActions('newBusiness', [
            'saveNewBusinessData',
            'updateBrokerCompany',
            'updateTransactionStatus'
        ]),

        ...mapMutations('newBusiness', [
            'SET_TRANSACTION',
            'SET_POLICY',
            'SET_ASSET',
            'SET_STATUS_FLAGS',
            'SET_SAVING',
            'SET_CLAIMS',
            'SET_DECLARATION_MODE',
            'SET_WIZARD_TITLE'
        ]),

        updateWizardTitle() {
            if (this.wizardAppData?.policy) {
                const policyName = this.wizardAppData.policy.Name;
                const policyNumber = this.wizardAppData.policy.K_PolicyNumber;
                const txnType = 'Take-Up';

                let title = policyNumber
                    ? `${txnType} - ${policyName} (${policyNumber})`
                    : `${txnType} - ${policyName}`;

                this.$store.commit('SET_WIZARD_TITLE', title);
            }
        },

        async handleDeclarationChange(value) {
            // Ensure value is uppercase for consistency
            const upperValue = value.toUpperCase();

            this.SET_TRANSACTION({
                ...this.transaction,
                K_DeclarationRequired: upperValue
            });

            // Set declaration mode
            this.SET_DECLARATION_MODE(upperValue === 'NO' ? 'manual' : 'form');

            await this.saveNewBusinessData();
        },

        async handleDeclarationTypeChange(value) {
            this.SET_DECLARATION_MODE(value === 'no' ? 'manual' : 'form');
            if (value === 'no') {
                // Clear any existing declaration form data
                this.SET_DECLARATION_FORM_DATA(null);
            }
            await this.saveWizardAppData();
        },

        async setBrokerCompany() {
            try {
                // First check if we already have K_BrokerCompany in wizardAppData
                if (this.wizardAppData?.transaction?.K_BrokerCompany) {
                    this.$store.commit('setBrokerCompany', this.wizardAppData.transaction.K_BrokerCompany);
                    return;
                }

                // If not, get user's default brand
                const { data } = await this.$commonDataService.getUserBrand(this.userId);
                if (data?.result?.brand) {
                    this.$store.commit('setBrokerCompany', data.result.brand);
                }
            } catch (error) {
                console.error('Error setting broker company:', error);
                // Default to CIB if there's an error
                this.$store.commit('setBrokerCompany', 'Collective Insurance Brokers');
            }
        },

        setTransactionBoxLink() {
            if (this.wizardAppData?.transaction?.K_TransactionBoxFolderId) {
                const boxLink = `https://collectiveib.app.box.com/folder/${this.wizardAppData.transaction.K_TransactionBoxFolderId}`;
                this.$store.commit('setTransactionBoxFolderLink', boxLink);
            }
        },

        initWizard: function () {
            // Initialize form wizard
            const wizard = new KTWizard("newbusiness_wizard", {
                startStep: 1, // Initial active step number
                clickableSteps: false, // Do not allow step clicking
            });

            this.wizardObj = wizard;

            // Before-Change event
            wizard.on("change", async (wizardObj) => {
                console.log("Change event - Current step:", wizardObj.getStep());
                this.clearError();
                return true; // Allow the change
            });

            // After-Change event
            wizard.on("changed", (wizardObj) => {
                const currentStep = wizardObj.getStep();
                console.log("Changed event - New step:", currentStep);
                this.saveWizardCurrentPage(currentStep);

                // Ensure loading is stopped after navigation
                this.stopPageLoading();

                setTimeout(() => {
                    KTUtil.scrollTop();
                    this.stopPageLoading();
                }, 500);
            });
        },

        async getReferenceData() {
            const { data } = await this.$newBusinessDataService.getReferenceData();
            const { allStrataCompanies, allInsurers, allProducts, activeUsers } = data.result;
            this.referenceData = {
                allStrataCompanies,
                allInsurers,
                allProducts,
                activeUsers
            };
        },

        handleAlertSaved(newAlertText) {
            if (this.wizardAppData && this.wizardAppData.asset) {
                this.wizardAppData.asset.K_Alert = newAlertText;
            }
        },

        getClientDeclarationEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("LOA_DECLARATION")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        isPageValid: async function (pageNumber) {
            let isValid = false;
            if (!this.$refs || !this.$refs.newbusinessWizard || !this.wizardAppData) {
                return isValid;
            }

            let page = this.wizardPages[pageNumber - 1];
            if (!page) {
                console.log(`Invalid page number ${pageNumber}`);
                return isValid;
            }

            let wizardPageId = page.id;
            let isDevelopment = process.env.NODE_ENV === "development";

            let childrenPages = this.$refs.newbusinessWizard.$children;
            if (Array.isArray(childrenPages)) {
                let currentPageComponent = _.find(childrenPages, (child) => {
                    return child.$el && child.$el.id === wizardPageId;
                });
                if (!currentPageComponent) {
                    if (isDevelopment) {
                        console.log(
                            `Could not find currentPageComponent for id ${wizardPageId}`
                        );
                    }
                } else {
                    let currentPageRef = currentPageComponent.$refs[wizardPageId];
                    if (!currentPageRef) {
                        if (isDevelopment) {
                            console.log(
                                `Could not find currentPageRef for id ${wizardPageId}`
                            );
                        }
                    } else {
                        isValid = await currentPageRef.validate();
                        if (!isValid && isDevelopment) {
                            console.log(currentPageRef.errors);
                        }
                    }
                }
            }
            return isValid;
        },

        goToNextPage: async function () {
            this.clearError();
            let isNavAllowed = false;

            if (this.isWizardReadOnly) {
                isNavAllowed = true;
            } else {
                isNavAllowed = await this.isPageValid(this.wizardObj.getStep());
            }

            if (isNavAllowed) {
                try {
                    console.log('Starting navigation...');
                    this.startPageLoading();
                    await this.saveWizardAppData();

                    const currentStep = this.wizardObj.getStep();
                    const declarationRequired = (this.transaction?.K_DeclarationRequired || 'NO').toUpperCase();

                    console.log('Current step:', currentStep);
                    console.log('Declaration Required:', declarationRequired);

                    if (currentStep === 1 && declarationRequired === 'NO') {
                        console.log('Attempting to skip to step 3');
                        this.wizardObj.goTo(3);
                        console.log('Navigation complete');
                    } else {
                        console.log('Performing normal navigation');
                        this.wizardObj.goNext();
                    }

                    // Ensure loading is stopped
                    setTimeout(() => {
                        this.stopPageLoading();
                    }, 500);

                } catch (error) {
                    console.error('Navigation error:', error);
                    this.stopPageLoading();
                    this.handleError(error);
                } finally {
                    // Double ensure loading is stopped
                    setTimeout(() => {
                        this.stopPageLoading();
                    }, 1000);
                }
            } else {
                this.stopPageLoading();
            }
        },

        goToPreviousPage: async function () {
            this.clearError();

            try {
                this.startPageLoading();
                await this.saveWizardAppData();

                const currentStep = this.wizardObj.getStep();
                const declarationRequired = (this.transaction?.K_DeclarationRequired || 'NO').toUpperCase();

                console.log('Current step:', currentStep);
                console.log('Declaration Required:', declarationRequired);

                if (currentStep === 3 && declarationRequired === 'NO') {
                    console.log('Skipping back to step 1');

                    // Add small delay before navigation
                    await new Promise(resolve => setTimeout(resolve, 100));

                    // Try direct navigation first
                    this.wizardObj.goTo(1);

                    // If that doesn't work, try force reset
                    if (this.wizardObj.getStep() !== 1) {
                        this.wizardObj.stop();
                        await new Promise(resolve => setTimeout(resolve, 50));
                        this.wizardObj.goFirst();
                        await new Promise(resolve => setTimeout(resolve, 50));
                        this.wizardObj.resume();
                    }

                    // Verify the step change
                    console.log('New step after navigation:', this.wizardObj.getStep());
                } else {
                    console.log('Normal navigation to previous step');
                    this.wizardObj.goPrev();
                }

                // Ensure loading is stopped after a delay
                setTimeout(() => {
                    this.stopPageLoading();
                }, 500);

            } catch (error) {
                console.error('Navigation error:', error);
                this.stopPageLoading();
                this.handleError(error);
            }
        },

        async getWizardAppData() {
            try {
                const { data } = await this.$newBusinessDataService.getWizardAppData();
                if (!data.result) {
                    throw new Error('Failed to get wizard application data');
                }

                // Update store with initial data
                this.SET_TRANSACTION(data.result.transaction);
                this.SET_POLICY(data.result.policy);
                this.SET_ASSET(data.result.asset);
                this.SET_STATUS_FLAGS(data.result.statusFlags);
                this.SET_CLAIMS(data.result.claims);

                this.wizardAppData = data.result;
                this.appDataString = stringify(this.wizardAppData);

                await this.setBrokerCompany();

                if (this.wizardAppData.policy && !this.wizardAppData.policy.K_PolicyNumber) {
                    const policyName = this.wizardAppData.policy.Name;
                    const txnType = 'Take-Up';
                    const title = `${txnType} - ${policyName}`;
                    this.$store.commit("setWizardTitle", title);
                }

                this.setHeaderInformation();
                this.setTransactionBoxLink();

                // Set starting step
                let startStep = 1;
                if (this.isWizardReadOnly) {
                    startStep = this.transaction.K_Type === "Take-Up" ? 3 : this.wizardPages.length;
                } else {
                    // Check declaration required when setting start page
                    if (this.transaction.K_DeclarationRequired?.toUpperCase() === 'NO' && this.transaction.K_WizardCurrentPage === 2) {
                        startStep = 3;
                    } else {
                        startStep = this.transaction.K_WizardCurrentPage || 1;
                    }
                }

                this.stopPageLoading();

                if (startStep !== 1) {
                    setTimeout(() => {
                        this.wizardObj.goTo(startStep);
                    }, 0);
                }

            } catch (error) {
                this.handleError(error);
            }
        },

        async handleSaveChanges(data) {
            try {
                this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                this.startPageLoading();
                this.isSaving = true;

                if (data) {
                    // Update store with new data
                    if (data.transaction) this.SET_TRANSACTION(data.transaction);
                    if (data.policy) {
                        this.SET_POLICY(data.policy);
                        // Update title when policy changes
                        this.updateWizardTitle();
                    }
                    if (data.asset) this.SET_ASSET(data.asset);
                }

                await this.saveNewBusinessData();
                this.setTransactionBoxLink();

                if (!this.statusFlags.policyDetailsEntered) {
                    await this.updateTransactionStatus('policyDetailsEntered');
                }

                return true;
            } catch (error) {
                this.handleError(error);
                throw error;
            } finally {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                this.stopPageLoading();
                this.isSaving = false;
            }
        },

        async saveWizardAppData(noSpinner = false) {
            if (this.isWizardReadOnly) return;

            try {
                if (!noSpinner) {
                    this.SET_SAVING(true);
                }

                const result = await this.saveNewBusinessData();

                // Update local wizardAppData for compatibility
                this.wizardAppData = {
                    ...this.wizardAppData,
                    transaction: this.transaction,
                    policy: this.policy,
                    asset: this.asset,
                    statusFlags: this.statusFlags
                };

                this.appDataString = stringify(this.wizardAppData);
                this.updateUiTransactionStatus();

                return result.result;
            } catch (error) {
                this.handleError(error);
            } finally {
                if (!noSpinner) {
                    this.SET_SAVING(false);
                }
            }
        },

        saveWizardCurrentPage: function (pageNumber) {
            if (this.isWizardReadOnly) {
                return;
            }

            this.$commonDataService
                .saveWizardCurrentPage(pageNumber)
                .catch((error) => {
                    this.handleError(error);
                });
        },

        saveQuoteToServer: function (quote) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();

            this.startPageLoading();
            // Save quote
            this.$newBusinessDataService
                .saveQuoteDetails(quote)
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();

                    // Reset quote comparison data (because it would have changed when quote was updated)
                    this.wizardAppData.quoteComparisonData = null;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        applyBrokerFeeToAllQuotes: function () {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();

            let feesAndCommissions = _.pick(this.wizardAppData.transaction, [
                "K_BrokerFee",
                "K_BrokerFeeGst",
            ]);
            this.startPageLoading();

            // Apply fees to all quotes
            this.$newBusinessDataService
                .applyBrokerFeeToAllQuotes(feesAndCommissions)
                .then(({ data }) => {
                    let updatedStatus = data.result;
                    if (updatedStatus) {
                        // Update appData flags
                        this.wizardAppData.statusFlags[updatedStatus.K_StatusFlag] = true;

                        // Update UI transaction status
                        let txn = this.wizardAppData.transaction;
                        txn.K_Status = updatedStatus.K_Status;
                        txn.K_StatusDetails = updatedStatus.K_StatusDetails;
                        txn.K_NextStep = updatedStatus.K_NextStep;
                        this.updateUiTransactionStatus();
                    }

                    this.$newBusinessDataService
                        .refreshQuotes()
                        .then(({ data }) => {
                            this.wizardAppData.quotes = data.result;
                            this.stopPageLoading();

                            this.updateWizardAppDataString();
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setRecommendedQuote: function (quote) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();

            this.$set(quote, "saving", true);
            let quoteId = quote.id;
            this.$newBusinessDataService
                .setRecommendedQuote(quote.id)
                .then(() => {
                    this.$newBusinessDataService
                        .refreshQuotes()
                        .then(({ data }) => {
                            this.wizardAppData.quotes = data.result;
                            let updatedQuote = _.find(this.wizardAppData.quotes, {
                                id: quoteId,
                            });
                            if (updatedQuote) {
                                this.$set(updatedQuote, "saving", false);
                                this.$set(updatedQuote, "K_RecommendedQuote", true);
                            }

                            this.updateWizardAppDataString();
                        })
                        .catch((error) => {
                            this.$set(quote, "saving", false);
                            this.handleError(error);
                        });
                })
                .catch((error) => {
                    this.$set(quote, "saving", false);
                    this.handleError(error);
                });
        },

        createQuoteComparisonTable: function () {
            this.clearError();
            if (this.isWizardReadOnly) {
                return;
            }

            // Check if a recommended-quote exists
            let recommendedQuote = _.find(this.wizardAppData.quotes, {
                K_RecommendedQuote: true,
            });
            if (!recommendedQuote || !recommendedQuote.id) {
                this.setError("Please set one of the quotes as the recommended quote.");
                return;
            }
            this.clearError();
            this.startPageLoading();
            this.$newBusinessDataService
                .getQuoteComparisonData()
                .then(({ data }) => {
                    this.wizardAppData.quoteComparisonData = data.result;

                    this.stopPageLoading();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        requestBrokerAdviceReview: function (brokerAdviceReviewData) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();
            this.startPageLoading();
            this.$newBusinessDataService
                .requestBrokerAdviceReview(
                    brokerAdviceReviewData.reviewer,
                    brokerAdviceReviewData.comments
                )
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        approveOrRejectBrokerAdvice: function (brokerAdviceManagerResponseData) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();
            this.startPageLoading();
            this.$newBusinessDataService
                .approveOrRejectBrokerAdvice(
                    brokerAdviceManagerResponseData.status,
                    brokerAdviceManagerResponseData.comments
                )
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        acceptQuote: function (quote) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();
            this.startPageLoading();
            this.$newBusinessDataService
                .acceptQuote(quote.id)
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setHeaderInformation() {
            if (this.wizardAppData) {
                // Update title
                this.updateWizardTitle();

                // Update transaction status on the header
                this.updateUiTransactionStatus();

                // Update Box link on the sticky-toolbar
                if (this.wizardAppData.transaction?.K_TransactionBoxFolderId) {
                    const boxLink = `https://collectiveib.app.box.com/folder/${this.wizardAppData.transaction.K_TransactionBoxFolderId}`;
                    this.$store.commit('setTransactionBoxFolderLink', boxLink);
                }
            }
        },

        updateWizardAppDataString: function () {
            this.wizardAppDataString = stringify(this.wizardAppData);
        },

        updateServerTransactionStatus: function (flagName) {
            let flagValue = this.wizardAppData.statusFlags[flagName];
            if (_.isNil(flagValue)) {
                flagValue = true;
            }
            this.$newBusinessDataService
                .updateTransactionStatus(flagName, flagValue)
                .then(({ data }) => {
                    let updatedStatus = data.result;
                    if (updatedStatus) {
                        // Update UI transaction status
                        let txn = this.wizardAppData.transaction;
                        txn.K_Status = updatedStatus.K_Status;
                        txn.K_StatusDetails = updatedStatus.K_StatusDetails;
                        txn.K_NextStep = updatedStatus.K_NextStep;
                        this.updateUiTransactionStatus();

                        // Update wizardAppDataString
                        this.wizardAppDataString = stringify(this.wizardAppData);
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        updateUiTransactionStatus: function () {
            if (this.wizardAppData && this.wizardAppData.transaction) {
                let txn = this.wizardAppData.transaction;
                let txnStatus = {
                    status: txn.K_Status,
                    statusDetails: txn.K_StatusDetails,
                    nextStep: txn.K_NextStep,
                };
                // Set transaction status in global store so that it can be shown on the header
                this.$store.commit("setTransactionStatus", txnStatus);
            }
        },

        refreshWizardAppData: function () {
            this.$newBusinessDataService
                .getWizardAppData()
                .then(({ data }) => {
                    this.wizardAppData = data.result;
                    this.wizardAppDataString = stringify(this.wizardAppData);

                    // Update transaction status and Box folder link
                    this.updateUiTransactionStatus();
                    this.setTransactionBoxLink();

                    this.stopPageLoading();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setTransactionBoxLink: function () {
            let transactionBoxFolderId = _.get(
                this.wizardAppData,
                "transaction.K_TransactionBoxFolderId"
            );
            if (transactionBoxFolderId) {
                let txnBoxFolderLink = `https://collectiveib.app.box.com/folder/${transactionBoxFolderId}`;

                // Set transaction status in global store so that it can be shown on the header
                this.$store.commit("setTransactionBoxFolderLink", txnBoxFolderLink);
            }
        },

        overrideDeclarationForm: function (overrideReason) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();

            this.startPageLoading();
            this.$newBusinessDataService
                .overrideDeclarationForm(overrideReason)
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        finaliseTransaction: function () {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();
            this.startPageLoading();

            // Save wizard-app-data to server
            this.$newBusinessDataService
                .saveWizardAppData(this.wizardAppData)
                .then((data) => {
                    this.wizardAppData = data.data.result;
                    this.wizardAppDataString = stringify(this.wizardAppData);

                    // Update transaction status on the header
                    this.updateUiTransactionStatus();

                    let txnType = this.wizardAppData.transaction.K_Type;
                    this.$newBusinessDataService
                        .finaliseTransaction()
                        .then(async () => {
                            // Refresh the wizard data to get updated status
                            await this.getWizardAppData();

                            this.stopPageLoading();

                            this.$nextTick(() => {
                                Swal.fire({
                                    title: `${txnType} Finalised`,
                                    html: `This ${txnType} transaction has been finalised.<br/>
                                       <span class="font-weight-bold">Please close this browser window.</span>`,
                                    icon: "success",
                                    allowOutsideClick: false,
                                    allowEnterKey: false,
                                    allowEscapeKey: false,
                                    showCancelButton: false,
                                    showCloseButton: false,
                                });
                            });
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setError: function (message) {
            this.errorAlert = message;

            // Ensure the error is visible in next tick
            this.$nextTick(() => {
                const alertElement = document.querySelector('.error-alert');
                if (alertElement) {
                    alertElement.scrollIntoView({ behavior: 'smooth' });
                }
            });
        },

        clearError: function () {
            this.errorAlert = null;
        },

        handleError: function (errorObj) {
            this.stopPageLoading();
            let errorMsg = null;

            if (errorObj) {
                // Handle string errors (including UI_ERROR prefix)
                if (typeof errorObj === 'string') {
                    if (errorObj.startsWith('UI_ERROR:')) {
                        errorMsg = errorObj.substring('UI_ERROR:'.length);
                    } else {
                        errorMsg = errorObj;
                    }
                }
                // Handle Axios errors
                else if (errorObj.response) {
                    const responseData = errorObj.response.data;
                    if (responseData && responseData.result) {
                        if (typeof responseData.result === 'string' && responseData.result.startsWith('UI_ERROR:')) {
                            errorMsg = responseData.result.substring('UI_ERROR:'.length);
                        } else {
                            errorMsg = responseData.result;
                        }
                    } else if (responseData && responseData.error && responseData.error.message) {
                        errorMsg = responseData.error.message;
                    }
                }
                // Handle other error objects
                else if (errorObj.message) {
                    errorMsg = errorObj.message;
                }
            }

            if (!errorMsg) {
                errorMsg = "An unexpected error has occurred. Please try again or contact KAVA Support.";
            }

            this.setError(errorMsg);


            // Optional: Show a more prominent error message for discarded transactions
            if (errorMsg.includes('transaction was discarded')) {
                Swal.fire({
                    title: 'Transaction Discarded',
                    text: errorMsg ? errorMsg.replace('UI_ERROR:', '') : errorMsg,
                    icon: 'warning',
                    allowOutsideClick: false,
                    showConfirmButton: false
                });
            }

            // Scroll to top to show error
            KTUtil.scrollTop();
        },

        startPageLoading: function () {
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        },

        stopPageLoading: function () {
            console.log('Stopping page loading...');
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

            // Double ensure loading is removed
            setTimeout(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }, 100);
        },
    },

    watch: {
        'wizardAppData.transaction.K_TransactionBoxFolderId': {
            handler(newVal) {
                if (newVal) {
                    this.setTransactionBoxLink();
                }
            },
            immediate: true
        },

        'transaction.K_DeclarationRequired': {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    this.SET_TRANSACTION({
                        ...this.transaction,
                        K_DeclarationRequired: 'NO'
                    });
                }
                // Ensure declaration mode matches the required setting
                this.SET_DECLARATION_MODE(
                    newVal?.toUpperCase() === 'NO' ? 'manual' : 'form'
                );
            }
        },
        'wizardAppData.policy': {
            handler(newVal) {
                if (newVal) {
                    this.updateWizardTitle();
                }
            },
            deep: true
        }
    }
};
</script>
