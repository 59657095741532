<template>
    <b-card body-class="px-2 py-2" class="mt-5 mb-5">
        <h4 class="fs-2x fw-bolder mb-0 text-center py-4" v-b-toggle="id">
            {{ configuration.title }}
            <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md ml-2" title="Click to Expand"></i>
            <i v-if="isExpanded" class="fas fa-angle-double-up fa-md ml-2" title="Click to Collapse"></i>
        </h4>
        <b-collapse :id="id" v-model="isExpanded">
            <div class="row">
                <div v-for="(chunk, index) in dataChunks" :key="index" class="col-sm-6 table-responsive">
                    <table class="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th width="45%">Name</th>
                                <th width="25%" v-if="showCurrentValue">
                                    {{ configuration.currentValueTitle }}
                                </th>
                                <th width="30%" v-if="showNewValue">
                                    {{ configuration.newValueTitle }}
                                </th>
                                <th v-if="configuration.allowDeletion"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in chunk" :key="index">
                                <td>
                                    <label class="col-form-label ml-2" style="text-align: left">{{
                                        item.displayName
                                    }}</label>
                                </td>
                                <td v-if="showCurrentValue">
                                    <label class="col-form-label" v-if="item.type === 'Text' ||
                                        item.type === 'Picklist' ||
                                        item.type === 'Integer' ||
                                        item.type === 'Percent' ||
                                        item.type === 'Decimal' ||
                                        item.type === 'MultiLine-Text'
                                        ">
                                        {{ item.value }}
                                    </label>
                                    <label v-if="item.type === 'Currency' && item.value" class="col-form-label">
                                        {{ item.value | currency("$", ",", 0, ".", "front", true) }}
                                    </label>
                                    <label v-if="item.type === 'Date' && item.value" class="col-form-label">
                                        {{
                                            item.value
                                            | dateParse("YYYY-MM-DD")
                                            | dateFormat("DD/MM/YYYY")
                                        }}
                                    </label>
                                </td>
                                <td v-if="showNewValue">
                                    <div v-b-tooltip.hover.left="item.newValue || 'no value'">
                                        <input v-if="item.type === 'Text'" type="text" class="form-control" maxlength="255"
                                            v-model="item.newValue" :disabled="isReadOnly" />
                                    </div>

                                    <div v-b-tooltip.hover.left="item.newValue || 'no value'">
                                        <textarea v-if="item.type === 'MultiLine-Text'" class="form-control min-rows"
                                            maxlength="2000" v-model="item.newValue" :disabled="isReadOnly" ref="textarea"
                                            @input="resizeTextarea" />
                                    </div>

                                    <div v-b-tooltip.hover.left="item.newValue || 'no value'">
                                        <input v-if="item.type === 'Integer' ||
                                            item.type === 'Percent' ||
                                            item.type === 'Decimal'
                                            " type="number" class="form-control" v-model="item.newValue"
                                            :disabled="isReadOnly" />
                                    </div>

                                    <div v-b-tooltip.hover.left="formattedValue(item.newValue)">
                                        <currency-input v-if="item.type === 'Currency'" class="form-control"
                                            v-model="item.newValue" :disabled="isReadOnly"
                                            :allow-negative="currencyConfig.allowNegative"
                                            :distraction-free="currencyConfig.distractionFree"
                                            :precision="currencyConfig.precision" />
                                    </div>

                                    <div v-b-tooltip.hover.left="formatTooltipDate(item.newValue)">
                                        <b-form-datepicker v-if="item.type === 'Date'" class="form-control"
                                            v-model="item.newValue" :locale="'en-AU'" :disabled="isReadOnly"
                                            :date-format-options="{
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                            }"></b-form-datepicker>
                                    </div>

                                    <div v-b-tooltip.hover.left="item.newValue || 'no value'">
                                        <b-form-select v-if="item.type === 'Picklist'" class="form-control"
                                            v-model="item.newValue" :disabled="isReadOnly">
                                            <option value=""></option>
                                            <option v-for="option in item.options" :key="option" :value="option">
                                                {{ option }}
                                            </option>
                                        </b-form-select>
                                    </div>

                                </td>

                                <td style="vertical-align: middle; background-color: white; border: none;">
                                    <button class="btn p-0" @click.prevent="deleteData(item)" :disabled="isReadOnly"
                                        v-if="configuration.allowDeletion && item.isCustomItem">
                                        <i class="fa fa-minus-circle fa-1.5x text-danger" style="opacity: 0.75;"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="configuration.allowAddition && !isReadOnly" class="mt-10">
                <span class="font-weight-bold">
                    To add a new entry, enter data in the fields below and click '+ Add'
                </span>
                <div class="row mt-5 mb-2">
                    <div class="col-2">
                        <input type="text" class="form-control" placeholder="Enter Name" v-model="objAddData.displayName"
                            :disabled="isReadOnly" />
                    </div>
                    <div class="col-2">
                        <b-form-select v-model="objAddData.type" :disabled="isReadOnly">
                            <option value="">Select Type</option>
                            <option value="Text">Text</option>
                            <option value="Currency">Currency</option>
                            <option value="Integer">Integer</option>
                            <option value="Decimal">Decimal</option>
                            <option value="Percent">Percent</option>
                        </b-form-select>
                    </div>
                    <div class="col-2">
                        <input v-if="!objAddData.type || objAddData.type === 'Text'" type="text" class="form-control"
                            maxlength="255" placeholder="Enter Value" v-model="objAddData.newValue"
                            :disabled="isReadOnly" />
                        <input v-if="objAddData.type === 'Integer' ||
                            objAddData.type === 'Decimal' ||
                            objAddData.type === 'Percent'
                            " type="number" class="form-control" v-model="objAddData.newValue"
                            :disabled="isReadOnly" />
                        <currency-input v-if="objAddData.type === 'Currency'" class="form-control"
                            v-model="objAddData.newValue" :disabled="isReadOnly"
                            :allow-negative="currencyConfig.allowNegative"
                            :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision" />
                    </div>
                    <div class="mt-2" v-if="configuration.allowAddition">
                    </div>
                    <button class="btn btn-sm btn-outline-primary" @click.prevent="addData()" :disabled="isReadOnly">
                        <i class="fas fa-plus fs-2x"></i>
                        {{ configuration.addButtonTitle }} Add
                    </button>
                </div>
                <p class="text-danger" v-if="fieldsIncomplete">All fields must be completed before adding.</p>
            </div>
        </b-collapse>

        <b-card border-variant="" body-class="px-2 py-2" class="mt-7 mb-7 mr-7 ml-7 pb-3" v-b-toggle="'disclosures'">
            <h4 class="text-center mt-5">Disclosures
                <i v-if="!isExpandedDisclosures" class="fas fa-angle-double-down fa-md ml-2" title="Click to Expand"></i>
                <i v-if="isExpandedDisclosures" class="fas fa-angle-double-up fa-md ml-2" title="Click to Collapse"></i>
            </h4>
        </b-card>
        <b-collapse id="disclosures" v-model="isExpandedDisclosures">

            <div v-for="(question, index) in disclosures" :key="index">
                <b-card body-class="px-2 py-2" class="mb-5 mr-7 ml-7 pt-4" border-variant="secondary">
                    <div class="form-group row">
                        <div class="col-12 font-weight-bold font-size-h5">
                            <div class="row pl-3">
                                <div class="col-1 text-success">
                                    {{ index + 1 }}.
                                </div>
                                <div class="col-11 mb-4" style="margin-left: -60px;">
                                    {{ question.displayName }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-2 ml-10">

                            <b-input-group :disabled="isReadOnly" size="lg" class="ml-4">
                                <b-form-checkbox switch v-model="switchValues[index]" class="mr-n2" :disabled="isReadOnly"
                                    @input="$emit('input', formData)"></b-form-checkbox>
                                <span class="pl-3 pt-1 font-weight-bold">{{ switchValues[index] ? 'Yes' : 'No' }}</span>
                            </b-input-group>
                        </div>

                        <div v-if="question.newValue === 'Yes' && question.children.length > 0" class="col-12 ml-10">
                            <div v-for="(child, cIndex) in question.children" :key="cIndex">
                                <template v-if="shouldShowChildItem(question, child)">
                                    <label class="col-12 col-form-label font-weight-bold mt-3">{{ index + 1 }}.{{ cIndex + 1
                                    }} -
                                        {{ child.displayName }}</label>
                                    <div v-if="child.displayName.includes('Attach')" class="col-12">
                                        <p class="text-primary">Please attach/view any attachments within the associated Box
                                            folder.</p>
                                    </div>
                                    <div class="col-6">
                                        <div v-b-tooltip.hover.left="child.newValue || 'no value'">
                                            <input v-if="child.type === 'Text'" type="text" class="form-control"
                                                maxlength="255" v-model="child.newValue" :disabled="isReadOnly"
                                                @input="$emit('input', formData)" />
                                        </div>

                                        <div v-b-tooltip.hover.left="child.newValue || 'no value'">
                                            <textarea v-if="child.type === 'MultiLine-Text'" class="form-control min-rows"
                                                maxlength="2000" v-model="child.newValue" :disabled="isReadOnly"
                                                @input="() => { resizeTextarea(); $emit('input', formData); }"
                                                ref="textarea" />
                                        </div>

                                        <div v-b-tooltip.hover.left="child.newValue || 'no value'">
                                            <input v-if="child.type === 'Integer' ||
                                                child.type === 'Decimal' ||
                                                child.type === 'Percent'
                                                " type="number" class="form-control" v-model="child.newValue"
                                                :disabled="isReadOnly" @input="$emit('input', formData)" />
                                        </div>

                                        <div v-b-tooltip.hover.left="formattedValue(child.newValue)">
                                            <currency-input v-if="child.type === 'Currency'" class="form-control"
                                                v-model="child.newValue" :disabled="isReadOnly"
                                                :allow-negative="currencyConfig.allowNegative"
                                                :distraction-free="currencyConfig.distractionFree"
                                                :precision="currencyConfig.precision" @input="$emit('input', formData)" />
                                        </div>

                                        <div v-b-tooltip.hover.left="formatTooltipDate(child.newValue)">
                                            <b-form-datepicker v-if="child.type === 'Date'" class="form-control"
                                                v-model="child.newValue" :locale="'en-AU'" :disabled="isReadOnly"
                                                :date-format-options="{
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                }" @input="$emit('input', formData)"></b-form-datepicker>
                                        </div>

                                        <div v-b-tooltip.hover.left="child.newValue || 'no value'">
                                            <b-form-select v-if="child.type === 'Picklist'" class="form-control"
                                                v-model="child.newValue" :disabled="isReadOnly"
                                                @input="$emit('input', formData)">
                                                <option value=""></option>
                                                <option v-for="option in child.options" :key="option" :value="option">
                                                    {{ option }}
                                                </option>
                                            </b-form-select>
                                        </div>

                                    </div>
                                </template>

                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
        </b-collapse>

    </b-card>
</template>

<style>
select {
    word-wrap: normal;
}

textarea {
    overflow: hidden;
}

.display-name {
    display: inline-block;
    text-indent: 30px;
}

.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
import _ from "lodash";

export default {
    name: "BuildingProperties",
    props: {
        formData: Array,
        configuration: Object,
        isReadOnly: Boolean,
    },

    computed: {
        dataChunks: function () {
            if (Array.isArray(this.formData) && this.formData.length > 0) {
                const deepFilter = (data) => {
                    return _.filter(data, (fd) => {
                        if (fd.isDisclosure || (!fd.isDisclosure && fd.children)) {
                            return false;
                        }
                        if (fd.children) {
                            fd.children = deepFilter(fd.children);
                        }
                        return true;
                    });
                };

                let filteredFormData = deepFilter(this.formData);

                const chunks = _.chunk(
                    filteredFormData,
                    Math.ceil(filteredFormData.length / 2)
                );
                return chunks;
            }
            return [];
        },


        disclosures: function () {
            if (Array.isArray(this.formData) && this.formData.length > 0) {
                let filteredFormData = _.filter(this.formData, { isDisclosure: true });
                return filteredFormData;
            }
            return [];
        },
        id: function () {
            if (this.configuration && this.configuration.title) {
                return this.configuration.title.split(" ").join("_");
            }
            return "";
        },
        showCurrentValue: function () {
            if (this.configuration && this.configuration.showCurrentValue === false) {
                return false;
            }
            return true;
        },
        showNewValue: function () {
            if (this.configuration && this.configuration.showNewValue === false) {
                return false;
            }
            return true;
        },

        getToggleValue() {
            return this.isYes ? "Yes" : "No";
        },

    },
    methods: {
        resizeTextarea() {
            this.$nextTick(() => {
                const textareas = document.querySelectorAll('textarea');
                textareas.forEach((textarea) => {
                    textarea.style.height = 'auto';
                    textarea.style.height = (textarea.scrollHeight + 5) + 'px';
                });
            });
        },

        addData: function () {
            if (
                !this.objAddData.displayName ||
                !this.objAddData.type ||
                !this.objAddData.newValue
            ) {
                this.fieldsIncomplete = true;
                return;
            }
            this.fieldsIncomplete = false;
            let apiKey = this.generateApiKey(this.objAddData.displayName);

            let keyExists = _.some(this.formData, { apiKey: apiKey });
            let suffix = 2;
            while (keyExists) {
                apiKey = apiKey =
                    this.generateApiKey(this.objAddData.displayName) + String(suffix);
                keyExists = _.some(this.formData, { apiKey: apiKey });
                ++suffix;
            }
            this.$set(this.objAddData, "apiKey", apiKey);
            this.formData.push({ ...this.objAddData, isCustomItem: true });
            this.objAddData = {};

        },

        deleteData: function (item) {
            if (item.isCustomItem) {
                this.$emit('delete-item', item, this.formData); //emit the form data as well
            }
        },

        generateApiKey: function (displayName) {
            let apiKey = displayName.trim().replace(/[^a-zA-Z0-9]/g, "");
            apiKey = _.camelCase(apiKey);
            return apiKey;
        },

        shouldShowChildItem(question, child) {
            if (!this.formData || this.formData.length === 0 || !child) {
                return true;
            }

            const buildingUsedForCommercialPurposes = this.formData.find(
                (item) => item.apiKey === 'K_BuildingUsedForCommercialPurposes'
            );
            if (!buildingUsedForCommercialPurposes || !Array.isArray(buildingUsedForCommercialPurposes.children)) {
                return true;
            }

            const commercialKitchen = buildingUsedForCommercialPurposes.children.find(
                (item) => item.apiKey === 'K_CommercialKitchenOnsite'
            );
            if (!commercialKitchen) {
                return true;
            }

            if (
                buildingUsedForCommercialPurposes.newValue === 'Yes' &&
                commercialKitchen && commercialKitchen.newValue !== 'Yes' && (child.apiKey === 'K_CapacityOfDeepFryersLitres' || child.apiKey === 'K_FiltersFlueDuctsCleanedProfessionally' || child.apiKey === 'K_HowOftenAreFiltersFlueAndDuctsCleaned')
            ) {
                return false; // Hide the specific child items
            }
            return true; // Show the child item by default
        },

        formattedValue(value) {
            if (value) {
                return parseFloat(value).toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'AUD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
            }
            return 'no value';
        },

        formatTooltipDate(date) {
            if (!date) return 'no value';
            const formattedDate = new Date(date).toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            });
            return formattedDate;
        },

    },
    data() {
        return {
            isExpanded: false,
            isExpandedDisclosures: false,
            objAddData: {},
            textareaRefs: [],
            fieldsIncomplete: false,
            currencyConfig: {
                allowNegative: false,
                distractionFree: false,
                precision: 0,
            },
            switchValues: []
        };
    },

    updated() {
        this.resizeTextarea();
    },

    mounted() {
        // Initialize the switch values array with the default values from formData
        this.switchValues = this.disclosures.map((question) =>
            question.newValue === 'Yes'
        );

        this.$nextTick(() => {
            // Initialize the switch values array with the default values from formData
            this.switchValues = this.disclosures.map((question) =>
                question.newValue === 'Yes'
            );
        });

        this.$nextTick(() => {
            this.resizeTextarea();
        });

    },
    watch: {
        switchValues: {
            handler(newValues) {
                // Update the formData based on switch changes
                newValues.forEach((value, index) => {
                    this.disclosures[index].newValue = value ? "Yes" : "No";
                });

                this.$emit('input', this.formData);
            },
            deep: true,
        },
    },
};
</script>
