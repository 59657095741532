<template>
    <ValidationObserver ref="wizardPage3" id="wizardPage3" name="wizardPage3">
        <div>
            <instructions :instructions-text="instructions" />

            <policy-alert class="mt-10" :app-data="appData" :is-read-only="isReadOnly"
                @alertSaved="$emit('alertSaved', $event)" @handleError="$emit('handleError', $event)" />

            <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
                <h4 class="font-weight-bold text-dark">
                    <i class="fas fa-file-alt fa-md text-primary"></i>
                    <span class="ml-2" style="vertical-align: center">Building and Insurance Details</span>
                </h4>

                <div class="text-center" v-if="declarationRequired === 'yes'">
                    <label class="col-form-label font-weight-bold font-size-lg" v-html="declarationStatus"></label>
                </div>

                <div v-if="declarationRequired === 'yes' && !declarationFinalised
                    ">
                    <div class="text-center">
                        <button class="btn btn-outline-danger" @click.prevent="openOverrideConfirmationDialog">
                            Broker Override
                        </button>
                    </div>
                    <div class="text-center">
                        <!-- Hidden input to validate if the declaration has been finalised -->
                        <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                            name="declarationFinalised" v-slot="{ errors }">
                            <input type="hidden" v-model="declarationFinalised" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <br />
                </div>

                <div>
                    <div>
                        <div class="text-center"
                            v-if="transaction.K_DeclarationFormLink && transaction.K_DeclarationRequired === 'Yes'">
                            <a :href="declarationFormLink" class="btn btn-primary" target="_blank">
                                <i class="fa-regular fa-clipboard fa-md mr-2"></i>
                                Review Declaration Form
                            </a>
                        </div>
                        <br />
                    </div>

                    <declaration-form v-if="displayData && displayData.sumInsuredData"
                        :formData="displayData.sumInsuredData" :configuration="sumsDataTableConfiguration"
                        :is-read-only="isReadOnly" @delete-item="deleteItem" @input="handleSumInsuredChange" />

                    <declaration-form :formData="displayData.excessData" :configuration="excessDataTableConfiguration"
                        :is-read-only="isReadOnly" @delete-item="deleteItem" @input="handleExcessChange" />

                    <!-- <building-properties :form-data="displayData.assetPropertiesData"
                        :configuration="buildingPropertiesConfiguration" :is-read-only="isReadOnly"
                        @delete-item="deleteItem" @input="handleBuildingPropertiesChange" /> -->

                    <new-business-building-properties :configuration="buildingPropertiesConfiguration"
                        :is-read-only="isReadOnly" />
                </div>

                <div class="d-flex justify-content-start mt-5">
                    <b-button class="btn btn-primary" :disabled="isReadOnly || isSaving" @click="saveChanges">
                        <b-spinner small v-if="isSaving" class="mr-1"></b-spinner>
                        <i class="fas fa-floppy-disk"></i> Save Changes
                    </b-button>
                </div>
            </b-card>

            <claims-history :is-read-only="isReadOnly" />

            <div>
                <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
                    <h4 class="font-weight-bold text-dark">
                        <i class="fas fa-circle-plus fa-md text-primary"></i>
                        <span class="ml-2" style="vertical-align: center">Endorsements / Conditions</span>
                    </h4>
                    <h6 class="font-weight-bold text-dark mt-6">
                        <textarea class="form-control mt-3 min-rows" v-model="endorsementsConditions"
                            @input="UPDATE_ENDORSEMENTS_CONDITIONS($event.target.value)" :disabled="isReadOnly"
                            style="width: 100%;" placeholder="If applicable" maxlength="32000"></textarea>
                        <small class="form-text text-muted">Max 32,000 characters</small>
                    </h6>
                    <div class="d-flex justify-content-start mt-5">
                        <b-button class="btn btn-primary" :disabled="isReadOnly || isSaving" @click.prevent="saveChanges"
                            v-if="showSaveButton">
                            <b-spinner small v-if="isSaving" class="mr-1"></b-spinner>
                            <i class="fas fa-floppy-disk"></i> Save Changes
                        </b-button>
                    </div>
                </b-card>
            </div>

            <b-modal v-if="!isReadOnly" ref="overrideConfirmationDialog" title="Confirm Broker Override" size="md"
                no-close-on-esc no-close-on-backdrop hide-header-close @ok="overrideDeclaration"
                @cancel="clearOverrideReason">
                Please enter the reason for overriding the Declaration Form.<span class="text-danger">*</span>
                <ValidationProvider rules="required|max:2000" name="overrideReason" v-slot="{ errors }">
                    <div class="form-group mt-5">
                        <b-textarea class="form-control min-rows" :value="overrideReason"
                            @input="SET_OVERRIDE_REASON"></b-textarea>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </b-modal>
        </div>
    </ValidationObserver>
</template>

<style>
.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

import _ from "lodash";
import Instructions from "../../../common/Instructions";
import DeclarationForm from "../../../common/DeclarationForm.vue";
import BuildingProperties from "../../../common/BuildingProperties.vue";
import PolicyAlert from "../../../common/PolicyAlert";
import ClaimsHistory from "./ClaimsHistory.vue";
import NewBusinessBuildingProperties from "./NewBusinessBuildingProperties.vue";
import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
    name: "ReviewPage",

    components: {
        Instructions,
        DeclarationForm,
        BuildingProperties,
        ClaimsHistory,
        PolicyAlert,
        NewBusinessBuildingProperties
    },

    props: {
        isReadOnly: Boolean
    },

    computed: {
        ...mapState('newBusiness', [
            'transaction',
            'policy',
            'claims',
            'asset',
            'declarationFormData',
            'declarationRequired', ,
            'overrideReason',
            'declarationMode',
            'declarationFormLink',
            'declarationData',
        ]),
        ...mapGetters('newBusiness', [
            'declarationStatus',
            'declarationFinalised',
            'declarationOverridden',
            'isManualMode',
            'isFormMode'
        ]),

        endorsementsConditions: {
            get() {
                return this.$store.state.newBusiness.endorsementsConditions;
            },
            set(value) {
                this.$store.commit('newBusiness/UPDATE_ENDORSEMENTS_CONDITIONS', value);
            }
        },

        appData() {
            return {
                asset: this.asset,
            };
        },

        shouldLoadDeclarationData() {
            return this.isFormMode && !this.declarationFormData;
        },

        displayData() {
            // In manual mode, use direct declaration data
            const sourceData = this.declarationRequired !== 'yes'
                ? this.declarationData
                : (this.declarationFormData?.data || this.declarationData);

            if (!sourceData) return {
                sumInsuredData: [],
                excessData: [],
                assetPropertiesData: [],
                claims: this.claims || []
            };

            // Transform the assetPropertiesData to ensure toggles use newValue
            // const transformedAssetPropertiesData = sourceData.assetPropertiesData?.map(prop => {
            //     if (prop.isDisclosure) {
            //         return {
            //             ...prop,
            //             // For toggles, ensure newValue is set if only value exists
            //             value: prop.newValue || prop.value || 'No',
            //             newValue: prop.newValue || prop.value || 'No',
            //             children: prop.children?.map(child => ({
            //                 ...child,
            //                 // Ensure child fields maintain their values
            //                 value: child.newValue || child.value || null,
            //                 newValue: child.newValue || child.value || null,
            //                 isDisclosure: child.isDisclosure || false
            //             }))
            //         };
            //     }
            //     return prop;
            // }) || [];


            console.log(sourceData.assetPropertiesData);
            return {
                sumInsuredData: sourceData.sumInsuredData || [],
                excessData: sourceData.excessData || [],
                assetPropertiesData: sourceData.assetPropertiesData || [],
                // assetPropertiesData: transformedAssetPropertiesData,
                claims: this.claims || []
            };
        },

        showSaveButton() {
            return !this.isReadOnly;
        },

        // declarationFinalised() {
        //     return this.transaction?.K_DeclarationFormStatus === "Submitted" ||
        //         this.transaction?.K_DeclarationFormStatus === "Overridden";
        // },

        // declarationFormLink() {
        //     return this.transaction?.K_DeclarationFormLink;
        // },

        instructions: function () {
            return "Please review and confirm the sums insured, excesses, disclosure information & claims history below.";
        },

        // showSaveButton() {
        //     return !this.isReadOnly;
        // },

        // hasDeclarationFormData() {
        //     return !!this.appData?.transaction?.K_DeclarationFormDataAttachmentId || !!this.appData?.declarationData?.sumInsuredData?.length;
        // },

        sumsDataTableConfiguration: function () {
            return {
                title: "Sums Insured",
                renewalTitle: "Value",
                showNewValue: true,
                showCurrentValue: false,
                allowDeletion: true,
                allowAddition: true,
                addButtonTitle: "",
            };
        },

        excessDataTableConfiguration: function () {
            return {
                title: "Excesses",
                renewalTitle: "Value",
                showNewValue: true,
                showCurrentValue: false,
                allowDeletion: true,
                allowAddition: true,
                addButtonTitle: "",
            };
        },

        buildingPropertiesConfiguration: function () {
            return {
                title: "Building Properties",
                newValueTitle: "Value",
                showNewValue: true,
                showCurrentValue: false,
                allowDeletion: true,
                allowAddition: true,
                addButtonTitle: "",
            };
        },

        // declarationOverridden: function () {
        //     let decStatus = _.get(
        //         this.appData,
        //         "transaction.K_DeclarationFormStatus"
        //     );
        //     return decStatus === "Overridden";
        // },

        // declarationStatus: function () {
        //     let decReceivedTimestamp = _.get(
        //         this.appData,
        //         "transaction.K_DeclarationFormSubmissionOverrideTime"
        //     );
        //     if (decReceivedTimestamp) {
        //         decReceivedTimestamp = moment
        //             .tz(decReceivedTimestamp, "Australia/Sydney")
        //             .format("DD/MM/YYYY hh:mm:ss A");
        //     }

        //     let decStatus = `Awaiting Declaration Form from the Strata contact.`;
        //     if (decReceivedTimestamp) {
        //         if (this.declarationOverridden) {
        //             let overridingBroker =
        //                 _.get(this.appData, "transaction.K_OverridingBroker") || "Broker";
        //             decStatus = `<span class="text-danger">Declaration Form was overridden by ${overridingBroker} on ${decReceivedTimestamp}.</span>`;
        //             let reason = _.get(this.appData, "transaction.K_OverrideReason");
        //             if (reason) {
        //                 decStatus = `${decStatus}<br/>Reason: ${reason}`;
        //             }
        //         } else {
        //             let decFormSubmittedBy =
        //                 _.get(this.appData, "transaction.K_DeclarationFormSubmittedBy") ||
        //                 "Strata Contact";
        //             decStatus = `<span class="text-primary">Declaration Form was submitted by ${decFormSubmittedBy} on ${decReceivedTimestamp}.</span>`;
        //         }
        //     }
        //     return decStatus;
        // },

    },

    data() {
        return {
            // overrideReason: null,
            // declarationFormData: null,
            isSaving: false,
            hasUnsavedChanges: false
        };
    },

    async mounted() {
        if (this.shouldLoadDeclarationData) {
            await this.loadDeclarationFormData();
        }
        await this.$store.dispatch('newBusiness/loadDeclarationData');
    },

    methods: {
        ...mapActions('newBusiness', [
            'loadDeclarationFormData',
            'saveNewBusinessData',
            'saveDeclarationFormData',
            'overrideDeclarationForm',
            'submitOverride'
        ]),
        ...mapMutations('newBusiness', [
            'UPDATE_ENDORSEMENTS_CONDITIONS',
            'UPDATE_DECLARATION_DATA',
            'SET_OVERRIDE_REASON',
            'CLEAR_OVERRIDE_REASON'
        ]),

        trackChanges() {
            this.hasUnsavedChanges = true;
        },

        async saveChanges() {
            try {
                this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                this.isSaving = true;

                if (this.claims?.length) {
                    this.appData.claims = this.claims;
                }

                if (this.isFormMode) {
                    // In form mode, save both declaration form and main data
                    await Promise.all([
                        this.saveDeclarationFormData(),
                        this.saveNewBusinessData()
                    ]);

                    // Refresh data from server to ensure we have latest state
                    await this.$store.dispatch('newBusiness/getWizardAppData');
                } else {
                    // In manual mode, save to transaction and refresh
                    await this.saveNewBusinessData();
                    await this.$store.dispatch('newBusiness/getWizardAppData');
                }

                this.hasUnsavedChanges = false;
            } catch (error) {
                this.$emit('handleError', error);
                console.error('Save failed:', error);
            } finally {
                this.isSaving = false;
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }
        },

        async handleSumInsuredChange(updatedData) {
            if (this.declarationFormData) {
                await this.saveDeclarationFormData({
                    ...this.declarationFormData,
                    data: {
                        ...this.declarationFormData.data,
                        sumInsuredData: updatedData
                    }
                });
            }
        },

        async handleExcessChange(updatedData) {
            if (this.declarationFormData) {
                const formData = {
                    ...this.declarationFormData,
                    data: {
                        ...this.declarationFormData.data,
                        excessData: updatedData
                    }
                };
                await this.saveDeclarationFormData(formData);

                // Also update store
                this.UPDATE_DECLARATION_DATA({
                    ...this.declarationData,
                    excessData: updatedData
                });
            }
        },

        async handleBuildingPropertiesChange(updatedData) {
            try {
                // Ensure we're working with fresh data
                const currentFormData = this.declarationFormData?.data || {};

                // Create a new object with the updated asset properties data
                const updatedFormData = {
                    ...currentFormData,
                    assetPropertiesData: updatedData.map(prop => {
                        if (prop.isDisclosure) {
                            return {
                                ...prop,
                                // Ensure both value and newValue are set
                                value: prop.newValue || prop.value || 'No',
                                newValue: prop.newValue || prop.value || 'No',
                                children: prop.children?.map(child => ({
                                    ...child,
                                    value: child.newValue || child.value || null,
                                    newValue: child.newValue || child.value || null
                                }))
                            };
                        }
                        return prop;
                    })
                };

                // Update the local state
                if (this.isFormMode) {
                    this.$store.commit('newBusiness/SET_DECLARATION_FORM_DATA', {
                        ...this.declarationFormData,
                        data: updatedFormData
                    });
                }


                console.log(`updatedFormData: ${JSON.stringify(updatedFormData, null, 2)}`)
                // Update store
                this.$store.commit('newBusiness/UPDATE_DECLARATION_DATA', updatedFormData);

                // Mark that we have unsaved changes
                this.hasUnsavedChanges = true;
            } catch (error) {
                this.$emit('handleError', error);
                console.error('Error updating building properties:', error);
            }
        },

        async handleDeclarationDataChange() {
            try {
                // Update store
                this.UPDATE_DECLARATION_DATA({
                    ...this.declarationFormData?.data,
                    assetPropertiesData: this.displayData.assetPropertiesData,
                });

                // Save to server
                await Promise.all([
                    this.saveDeclarationFormData({
                        data: {
                            ...this.declarationFormData?.data,
                            assetPropertiesData: this.displayData.assetPropertiesData
                        }
                    }),
                    this.saveNewBusinessData()
                ]);
            } catch (error) {
                this.$emit('handleError', error);
                console.error('Error saving declaration data:', error);
            }
        },

        openOverrideConfirmationDialog: function () {
            this.$refs["overrideConfirmationDialog"].show();
        },

        handleClaimsUpdate(updatedClaims) {
            // Emit for local handling if necessary
            this.$emit('update:appData', {
                ...this.appData,
                claims: updatedClaims
            });

            // Commit the mutation to update the store state
            this.$store.commit('UPDATE_CLAIMS', updatedClaims);

            // Optionally save the updated claims to the server
            this.$store.dispatch('saveClaims').catch((error) => {
            });
            console.error('Error saving claims:', error);
        },

        async saveClaims(claimsToSave) {
            try {
                const updatedAppData = {
                    ...this.appData,
                    claims: claimsToSave
                };

                await this.$newBusinessDataService.saveWizardAppData(updatedAppData);

            } catch (error) {
            }
            console.error('Error saving claims:', error);
        },

        async handleOverrideDeclaration(reason) {
            if (!reason) return;
            try {
                await this.overrideDeclarationForm(reason);
                this.$refs.overrideConfirmationDialog.hide();
            } catch (error) {
                this.$emit('handleError', error);
            }
        },

        async overrideDeclaration(bvModalEvt) {
            if (!this.overrideReason) {
                bvModalEvt.preventDefault();
                return;
            }
            try {
                await this.submitOverride();
                this.$refs.overrideConfirmationDialog.hide();
            } catch (error) {
                this.$emit('handleError', error);
                bvModalEvt.preventDefault();
            }
        },

        clearOverrideReason() {
            this.CLEAR_OVERRIDE_REASON();
        },

        deleteItem(item, formData) {
            let index = formData.findIndex(dataItem => dataItem === item);
            if (index !== -1) {
                formData.splice(index, 1);
                // Trigger declaration data change handler after deletion
                this.handleDeclarationDataChange();
            }
        },

        async loadDeclarationFormData() {
            // Only proceed if we're in form mode
            if (!this.isFormMode) return;

            try {
                const response = await this.$newBusinessDataService.getDeclarationFormData();
                if (response?.data?.result) {
                    this.SET_DECLARATION_FORM_DATA(response.data.result);
                }
            } catch (error) {
            }
            console.error('Error loading declaration form data:', error);
        }
    },

    watch: {
        // Watch for changes in appData to check if we need to load declaration data
        'transaction.K_DeclarationFormDataAttachmentId': {
            immediate: true,
            async handler(newVal) {
                if (newVal && this.shouldLoadDeclarationData) {
                    await this.loadDeclarationFormData();
                }
            }
        },

        'displayData.sumInsuredData': {
            deep: true,
            handler() {
                this.trackChanges();
            }
        },
        'displayData.excessData': {
            deep: true,
            handler() {
                this.trackChanges();
            }
        },
        'displayData.assetPropertiesData': {
            deep: true,
            handler() {
                this.trackChanges();
            }
        },
        'appData.policy': {
            deep: true,
            handler() {
                this.trackChanges();
            }
        }
    },
};
</script>
